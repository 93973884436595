// TOP LEVEL IMPORTS
import { Route, Switch } from "react-router-dom";
// PUBLIC
import PublicRoute from "components/route-components/PublicRoute";
import ClientRoute from "components/route-components/ClientRoute";
import AdminRoute from "components/route-components/AdminRoute";
// COMPONENTS
import Loading from "components/common/Loading";
import ErrorState from "components/common/ErrorState";
// LAYOUTS
import PublicLayout from "components/layouts/PublicLayout";
import AdminLayout from "components/layouts/AdminLayout";
import ClientLayout from "components/layouts/ClientLayout";
// CLIENT
import ClientHome from "./client-home";
// ADMIN
import AdminAnalysis from "./admin-analysis";
import AdminClients from "./admin-clients";
import AdminUsers from "./admin-users";
import AdminSessions from "./admin-sessions";
// AUTH
import AuthLogin from "./auth-login";
import AuthForgotPassword from "./auth-forgot-password";
import AuthResetPassword from "./auth-reset-password";
// GRAPHQL
import { useCurrentUserQuery } from "generated/graphql";
// LIB
import config from "lib/config";
import AdminSession from "components/modals/SubjectSessions";

export default function AppRoutes() {
  const { data, loading, error } = useCurrentUserQuery({
    pollInterval: 60000,
    fetchPolicy: "network-only",
  });

  let currentUser = data?.currentUser;

  let errorExists = error?.message;

  if (loading) return <Loading />;

  if (
    errorExists === "Network error: Failed to fetch" ||
    errorExists === "Failed to fetch"
  ) {
    return (
      <PublicLayout style={{ paddingTop: 88 }}>
        <ErrorState
          title="We seem to be having trouble connecting..."
          subtitle={`Please try to refresh. If the problem persists, please reach out to us at ${config.supportEmail}`}
        />
      </PublicLayout>
    );
  }

  // all other errors (that are not in our white listed errors list) just print to screen for now, unless it's a white listed error
  if (errorExists) {
    return (
      <PublicLayout>
        <ErrorState
          title={`Something went wrong connecting to ${config.appName}...x`}
        />
      </PublicLayout>
    );
  }

  const sharedProps = {
    currentUser,
  };

  return (
    <>
      <Switch>
        {/* CLIENT ROUTES */}
        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/sessions", "/sessions"]}
          component={AdminSessions}
          {...sharedProps}
        />

        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/analysis", "/analysis"]}
          component={AdminAnalysis}
          {...sharedProps}
        />

        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/clients"]}
          component={AdminClients}
          {...sharedProps}
        />

        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/users"]}
          component={AdminUsers}
          {...sharedProps}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/sessions", "/admin"]}
          component={AdminSession}
          {...sharedProps}
        />
        {/* CLIENT ROUTES */}
        <ClientRoute
          exact
          layout={ClientLayout}
          path={["/app/home", "/app"]}
          component={ClientHome}
          {...sharedProps}
        />
        {/* PUBLIC ROUTES */}
        <PublicRoute
          exact
          layout={PublicLayout}
          path={["/login", "/"]}
          component={AuthLogin}
          {...sharedProps}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path={["/forgot-password"]}
          component={AuthForgotPassword}
          {...sharedProps}
        />
        <PublicRoute
          exact
          layout={PublicLayout}
          path={["/reset-password/:token"]}
          component={AuthResetPassword}
          {...sharedProps}
        />
        <Route
          render={() => (
            <PublicLayout>
              <div
                style={{
                  height: "90vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ErrorState
                  title="404 Page Not Found"
                  subtitle={`We could not find this page. Please go back to the last page or if the problem persists, reach out to us at ${config.supportEmail}`}
                />
              </div>
            </PublicLayout>
          )}
        />
      </Switch>
    </>
  );
}
