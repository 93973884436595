import Table from "components/common/Table";
import {
  GetManyUsersDocument,
  GetManyUsersQueryVariables,
  Study,
  Trial,
  useCreateNewUserMutation,
  useFindStudiesQuery,
  useGetManyUsersQuery,
  useResendInviteEmailMutation,
  UserProfile,
  UserTypeEnum,
  useUpdateOneUserMutation,
} from "generated/graphql";
import useUrlChange from "lib/hooks/useUrlChange";
import ButtonText from "components/common/ButtonText";
import Button from "components/common/Button";
import Loading from "components/common/Loading";
import Drawer from "components/common/Drawer";
import UserForm from "components/forms/UserForm";
import { useCallback, useEffect, useState } from "react";
import StudiesCard from "components/common/StudiesCard";
import message from "components/common/message";
import styled from "styled-components";
import Tooltip from "components/common/Tooltip";
import moment from "moment";
import theme from "lib/theme";
import { SearchOutlined } from "@ant-design/icons";
import SwitchInput from "components/inputs/SwitchInput";
import FilterColumns from "components/common/FilterColumn";
import FilterColNames from "components/common/FilterColNames";
import PrimaryButton from "components/common/Button";
import { DocumentNode } from "graphql";

const ActionsBtn = styled.button`
  background: transparent;
  border: 0px;
  color: ${(p) => p.theme.colors.primary4};
  cursor: pointer;
  padding: 0px;
  font-weight: 600 !important;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

const filterStudiesByOrg = (
  recordStudies: Trial[] = [],
  studies: Study[] = []
) => {
  return recordStudies.filter((recordStudy) =>
    studies.some((study) => study.id === recordStudy.id)
  );
};

export type UsersSite = {
  __typename?: "UsersSite";
  id: string;
  name?: string;
  studies?: string[] | any;
  email?: string;
  invite?: boolean;
  active?: boolean;
};

export default function Users({
  organizationId,
  onKeyChange,
}: {
  organizationId: string;
  onKeyChange: any;
}) {
  const { onUrlChange } = useUrlChange();
  const [addNew, setAddNew] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<UserProfile>({});
  const [userId, setUserId] = useState<string>("");
  const [createNewUserMutation, { loading: creating }] =
    useCreateNewUserMutation();
  const [updateUser] = useUpdateOneUserMutation();
  const [resendInviteEmailMutation, { loading: resendingInvite }] =
    useResendInviteEmailMutation();
  const { data, loading, refetch } = useGetManyUsersQuery({
    variables: {
      organizationId: organizationId,
    },
  });
  const { data: studiesData } = useFindStudiesQuery({
    variables: {
      clientId: organizationId,
    },
  });

  const onEditCallback = useCallback(
    (record: UserProfile) => () => {
      setAddNew(true);
      setEdit(true);
      setUserId(record?.id as string);
      setEditData({
        ...record,
        trials: filterStudiesByOrg(
          record.trials as Trial[],
          studiesData?.findStudies.items as Study[]
        ),
      });
    },
    [studiesData?.findStudies?.items]
  );

  const dataActiveUsers =
    data?.getManyUsers?.reduce(
      (acc, user) => ({
        ...acc,
        [user?.id ?? "none"]:
          !(user.deactivated ?? false) && (user.acceptedInvite ?? false),
      }),
      {} as Record<string, boolean>
    ) ?? {};

  const [activeUsers, setActiveUsers] = useState(dataActiveUsers);

  const handleCreate = async (values: any) => {
    const refetchTrials: {
      query: DocumentNode;
      variables: GetManyUsersQueryVariables;
    } = {
      query: GetManyUsersDocument,
      variables: {
        organizationId: organizationId,
        userType: UserTypeEnum.SuperAdmin,
      },
    };
    try {
      await createNewUserMutation({
        variables: {
          params: {
            email: values?.email,
            firstName: values?.firstName,
            lastName: values?.lastName,
            organizationId,
            trialIds: values?.trialIds,
            userKind: values?.userKind,
          },
        },
        refetchQueries: [refetchTrials],
      });
      setAddNew(false);
      setEdit(false);
      setEditData({});
      await refetch();
      message.success("New user added");
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async (values: any) => {
    try {
      await updateUser({
        variables: {
          id: userId,
          params: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            trialIds: values?.trialIds,
          },
        },

        refetchQueries: [
          {
            query: GetManyUsersDocument,
          },
        ],
      });
      setAddNew(false);
      setEdit(false);
      setEditData({});
      message.success("The user was updated");
    } catch (err) {
      console.log(err);
    }
  };
  const mapRecord =
    data?.getManyUsers?.reduce?.((acc, key: UserProfile | any) => {
      const trials = key?.trials ?? [];
      return [...acc, ...trials];
    }, [] as UserProfile[]) ?? [];
  const toggleUserActive = useCallback(
    (userId: string, active: boolean) => {
      (async () => {
        let messageKey = `toggle-user-active-${userId}`;
        const activatingOrDeactivating = active ? "Activating" : "Deactivating";
        const previousValue = activeUsers[userId];

        message.loading({
          content: `${activatingOrDeactivating} user...`,
          key: messageKey,
        });
        try {
          setActiveUsers((prev) => ({ ...prev, [userId]: active }));

          const { data, errors } = await updateUser({
            variables: {
              id: userId,
              params: {
                deactivated: !active,
              },
            },
          });

          if (
            (errors?.length ?? 0) > 0 ||
            data?.updateOneUser?.deactivated === active
          ) {
            throw errors?.map((e) => e.message).join(", ") ?? "Unknown Errorr";
          }

          const activatedOrDeactivated = active ? "activated" : "deactivated";
          message.success({
            content: `User ${activatedOrDeactivated}.`,
            key: messageKey,
          });
        } catch (error) {
          setActiveUsers((prev) => ({ ...prev, [userId]: previousValue }));
          console.error(error);
          message.error({
            content: `There was a problem ${activatingOrDeactivating.toLowerCase()} user.`,
            key: messageKey,
          });
        }
      })();
    },
    [setActiveUsers, activeUsers]
  );
  const columns = [
    {
      title: "Names",
      align: "left",
      with: "20%",
      render: (record: UserProfile) =>
        `${record.firstName}  ${record.lastName}`,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColumns
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={data?.getManyUsers as UserProfile[]}
            placeHolder="search By Name"
          />
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      onFilter: (value: string, record: UserProfile) => {
        return (
          record.firstName
            ?.toLowerCase()
            .indexOf(value.toString().toLowerCase()) === 0
        );
      },
    },
    {
      title: "Email",
      render: (record: UserProfile) => record.email,
    },
    {
      title: "Studies",
      key: "studies",
      render: (record: UserProfile) => {
        const filteredStudies = filterStudiesByOrg(
          record.trials as Trial[],
          studiesData?.findStudies.items as Study[]
        );
        return (
          <div style={{ display: "flex" }}>
            {filteredStudies.slice(0, 2).map((item) => {
              return (
                <StudiesCard
                  key={item.id}
                  onClick={() => {
                    // onUrlChange({ trialsId: item.id });
                    onUrlChange({
                      studyId: item?.id,
                    });
                    onKeyChange("2");
                  }}
                >
                  {item.name}
                </StudiesCard>
              );
            })}
          </div>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColNames
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={mapRecord}
            placeHolder="Search by Study"
          />
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      onFilter: (value: string, record: UserProfile) => {
        const mapRecordFilter =
          record?.trials?.reduce?.((acc, key: any) => {
            return key?.name;
          }, "" as string) ?? "";

        return (
          mapRecordFilter
            .toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) === 0
        );
      },
    },
    {
      title: "Invite",
      width: "12%",
      render: ({ id, acceptedInvite, recentlySentInvite }: UserProfile) => {
        if (recentlySentInvite) {
          return `Sent ${moment(parseInt(recentlySentInvite)).fromNow()}`;
        }

        return acceptedInvite ? (
          "Accepted"
        ) : (
          <Tooltip title="send another invite email">
            <ActionsBtn
              disabled={resendingInvite}
              style={{ color: theme.colors.primary4, fontWeight: 600 }}
              onClick={async () => {
                try {
                  if (!id) return;
                  await resendInviteEmailMutation({
                    variables: {
                      userId: id,
                    },
                    refetchQueries: [
                      {
                        query: GetManyUsersDocument,
                        variables: {
                          organizationId,
                          userType: UserTypeEnum.Client,
                        },
                      },
                    ],
                  });
                  message.success("Invite resent");
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {!resendingInvite ? "Resend Invite" : "Sending..."}
            </ActionsBtn>
          </Tooltip>
        );
      },
    },

    {
      title: "Active",
      width: "10%",
      render: (record: UserProfile) => (
        <SwitchInput
          style={{
            width: 50,
          }}
          checked={activeUsers[record?.id ?? "none"] ?? false}
          disabled={!(record.acceptedInvite ?? true)}
          onChange={(newAction) =>
            toggleUserActive(record?.id ?? "none", newAction)
          }
        />
      ),
    },
    {
      title: "Edit",
      render: (record: UserProfile) => {
        return (
          <PrimaryButton onClick={onEditCallback(record)}>Edit</PrimaryButton>
        );
      },
    },
  ];

  useEffect(() => {
    setActiveUsers(
      () =>
        data?.getManyUsers?.reduce(
          (acc, user) => ({
            ...acc,
            [user?.id ?? "none"]:
              !(user.deactivated ?? false) && (user.acceptedInvite ?? false),
          }),
          {} as Record<string, boolean>
        ) ?? {}
    );
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Button
        onClick={() => {
          setAddNew(true);
          setEdit(false);
        }}
        style={{ margin: 16, marginLeft: 0, width: 130, height: "33px" }}
      >
        Add User
      </Button>
      <Table
        dataSource={
          data?.getManyUsers?.map((u) => ({ key: u.id, ...u })) as UserProfile[]
        }
        columns={columns?.map((item: any) => item)}
      />
      <Drawer
        title={
          edit
            ? `Edit User ${editData?.firstName} ${editData?.lastName}`
            : "Create New User"
        }
        placement="right"
        destroyOnClose
        width={500}
        onClose={() => {
          setAddNew(false);
          setEdit(false);
          setEditData({});
        }}
        visible={addNew ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <UserForm
          onSubmit={edit ? handleUpdate : handleCreate}
          onCancel={() => {
            setAddNew(false);
            setEdit(false);
            setEditData({});
          }}
          organizationId={organizationId}
          loading={creating}
          editData={editData}
          edit={edit}
        />
      </Drawer>
    </div>
  );
}
