import {
  Study,
  SessionReference,
  useFindStudiesQuery,
  useCreateStudyMutation,
  useReleaseSessionsMutation,
  FindStudiesQueryVariables,
  FindStudiesDocument,
  StudyInput,
  // StudyInput,
  // SubjectGroupInput,
  // useUpdateOneTrialMutation,
} from "generated/graphql";
import moment from "moment";
import theme from "lib/theme";
import { Divider } from "antd";
import styled from "styled-components";
import { DocumentNode } from "graphql";
import Text from "components/text/Text";
import { useEffect, useState } from "react";
import Table from "components/common/Table";
import { ColumnsType } from "antd/lib/table";
import Drawer from "components/common/Drawer";
import Caption from "components/text/Caption";
import { DeepPartial } from "react-hook-form";
import Button from "components/common/Button";
import message from "components/common/message";
import Loading from "components/common/Loading";
import useUrlChange from "lib/hooks/useUrlChange";
import { SearchOutlined } from "@ant-design/icons";
import StudyForm from "components/forms/StudyForm";
import PrimaryButton from "components/common/Button";
import SwitchInput from "components/inputs/SwitchInput";
import FilterColNames from "components/common/FilterColNames";
import SubjetsStudies from "pages/admin-clients/ClientDetail/Studies/Subjects";
import SessionRefDates from "pages/admin-clients/ClientDetail/Studies/Sessiondates";
import StudySettingsForm from "pages/admin-clients/ClientDetail/Studies/StudySettingsForm";
import StudySites from "./Sites";
import SitesNumberRenderer from "./SitesNumberRenderer";

const TitleExpandRow = styled.h3`
  color: ${({ theme }) => theme.colors.buttonSelect};
`;

const ExpandedRow = styled.div`
  display: flex;
  gap: 20px;
`;

const ExpandedItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExpandedItemSubject = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export type StudiesSideTypes = {
  __typename?: "StudiesSideTypes";
  id: string;
  name?: string;
  starDate?: string[] | any;
  endDate?: string[] | any;
  subjectProgress?: number;
  subjectCompleted?: number;
  active?: boolean;
};

export default function Studies({
  organizationId,
  studyId,
  onKeyChange,
}: {
  organizationId: string;
  studyId: string;
  onKeyChange: any;
}) {
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    offset: 0,
    total: 10,
    limit: 10,
  });
  const { onUrlChange } = useUrlChange();
  const [addNew, setAddNew] = useState<boolean>(false);
  const { data, loading, error } = useFindStudiesQuery({
    variables: {
      clientId: organizationId,
      pagination: {
        offset: (pagination.current - 1) * pagination.limit,
        limit: pagination.limit,
      },
    },
  });
  const [createStudyMutation, { loading: creating }] = useCreateStudyMutation();
  useReleaseSessionsMutation();

  useEffect(() => {
    setPagination({ ...pagination, total: data?.findStudies.count || 10 });
  }, [data]);

  const columns: ColumnsType<Study> = [
    {
      title: "Study Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColNames
            setSelectedKeys={setSelectedKeys}
            selectedKeys={
              studyId
                ? selectedKeys.length > 0 && selectedKeys[0]
                  ? [
                      ...selectedKeys,
                      data?.findStudies?.items.find(
                        (study) => study?.id === studyId
                      )?.name,
                    ]
                  : [
                      data?.findStudies?.items.find(
                        (study) => study?.id === studyId
                      )?.name,
                    ]
                : selectedKeys
            }
            confirm={confirm}
            clearFilters={() => {
              clearFilters();
              onUrlChange({
                studyId: null,
              });
            }}
            dataSource={data?.findStudies.items as Study[]}
            placeHolder="Search By Study Name"
          />
        );
      },
      onFilter: (value, record) => {
        return (
          record.name?.toLowerCase().indexOf(value.toString().toLowerCase()) ===
          0
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
    },
    {
      title: "Sites",
      align: "center",
      render: (record: Study) => (
        <SitesNumberRenderer
          trialsId={record?.id}
          organizationId={organizationId}
        />
      ),
    },
    {
      title: "Start Date",
      align: "center",
      render: (record: Study) => (
        <Text style={{ color: "#515151" }}>
          {record?.startDate
            ? moment(record?.startDate)?.format("MM/DD/YY")
            : ""}
        </Text>
      ),
    },
    {
      title: "End Date",
      align: "center",
      render: (record: Study) => (
        <Text style={{ color: "#515151" }}>
          {record?.endDate ? moment(record?.endDate)?.format("MM/DD/YY") : ""}
        </Text>
      ),
    },
    {
      title: "Subjects in Progress",
      align: "center",
      render: (record: Study) => (
        <Text style={{ color: "#515151", textAlign: "center" }}>
          {Number(record?.totalSubjects) - Number(record?.completedSubjects)}
        </Text>
      ),
      sorter: (a: Study, b: Study) => {
        const aString = Number(a?.totalSubjects);
        const bString = Number(b?.totalSubjects);

        return Number(aString) - Number(bString);
      },
    },
    {
      title: "Subjects Completed",
      align: "center",
      render: (record: Study) => (
        <Text style={{ color: "#515151", textAlign: "center" }}>
          {record.completedSubjects}
        </Text>
      ),
      sorter: (a: Study, b: Study) => {
        const aString = a?.completedSubjects;
        const bString = b?.completedSubjects;

        return Number(aString) - Number(bString);
      },
    },
    {
      title: "Active",
      align: "center",
      render: (record: Study) => (
        <div onClick={(e) => e.stopPropagation()}>
          <SwitchInput
            defaultChecked={!!record.active}
            onChange={async (checked: boolean) =>
              // this log is only to ensure that the dat is correct when we will connect with the real data
              console.log(checked, record.active, "Information to work!")
            }
          />
        </div>
      ),
    },
  ];

  const handleCreateTrial = async (values: DeepPartial<StudyInput>) => {
    try {
      const refetchTrials: {
        query: DocumentNode;
        variables: FindStudiesQueryVariables;
      } = {
        query: FindStudiesDocument,
        variables: {
          clientId: organizationId,
        },
      };

      await createStudyMutation({
        variables: {
          input: {
            name: values?.name || "Study random",
            clientId: organizationId,
          },
        },
        refetchQueries: [refetchTrials],
      });
      setAddNew(false);
      message.success("New study added");
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Caption>Something went wrong</Caption>;
  }
  return (
    <div>
      <Button
        onClick={() => setAddNew(true)}
        style={{ margin: 16, marginLeft: 0, height: "33px" }}
      >
        Add Study
      </Button>
      <Table
        dataSource={
          studyId
            ? (data?.findStudies.items.filter(
                (study) => study?.id === studyId
              ) as Study[])
            : (data?.findStudies.items as Study[])
        }
        columns={columns?.map((item: any) => item)}
        rowKey="id"
        className="tableClass"
        expandRowByClick
        rowClassName={(_record, index) =>
          Boolean(index % 2) ? "table-row-bg" : ""
        }
        onChange={(tablePaginationObject, filters) => {
          if (filters?.name !== null) {
            const finds = data?.findStudies.items.filter(
              (study) =>
                filters?.name?.find((filter) => study?.name === filter) !==
                undefined
            );
            setPagination({ ...tablePaginationObject, total: finds?.length });
          } else {
            setPagination({
              ...tablePaginationObject,
              total: data?.findStudies?.count,
            });
          }
        }}
        pagination={pagination}
        expandable={{
          expandedRowRender: (record: Study) => (
            <>
              <ExpandedRow>
                <ExpandedItem style={{ width: "180px" }}>
                  <TitleExpandRow>Selected Metrics</TitleExpandRow>
                  <StudySettingsForm defaultValues={record} />
                </ExpandedItem>
                <Divider
                  type="vertical"
                  style={{
                    height: "265px",
                  }}
                />
                <ExpandedItem style={{ width: "60px" }}>
                  <TitleExpandRow>Sites</TitleExpandRow>
                  <StudySites
                    selectSite={({ trialsId }: { trialsId: string }) => {
                      onUrlChange({ trialsId: trialsId });
                      onKeyChange("3");
                    }}
                    trialsId={record?.id}
                    organizationId={organizationId}
                  />
                </ExpandedItem>
                <Divider
                  type="vertical"
                  style={{
                    height: "265px",
                  }}
                />
                <ExpandedItem style={{ width: "260px" }}>
                  <TitleExpandRow> Session Reference Dates</TitleExpandRow>
                  <SessionRefDates
                    id={record?.id}
                    defaultValues={
                      record?.sessionReferences as SessionReference[]
                    }
                  />
                </ExpandedItem>
                <Divider
                  type="vertical"
                  style={{
                    height: "265px",
                  }}
                />
                <ExpandedItem style={{ minWidth: "500px", width: "50%" }}>
                  <TitleExpandRow>Subjects</TitleExpandRow>
                  <SubjetsStudies
                    defaultValues={record?.subjectGroups ?? []}
                    trialsId={record?.id}
                    organizationId={organizationId}
                  />
                </ExpandedItem>
                {/* <ExpandedItemSubject>
                  <TitleExpandRow> Session Reference Dates</TitleExpandRow>
                  <SessionRefDates
                    id={record?.id}
                    defaultValues={
                      record?.sessionReferences as SessionReference[]
                    }
                  />
                  <Divider type="horizontal" />
                  <TitleExpandRow>Subjects</TitleExpandRow>
                  <SubjetsStudies
                    defaultValues={record?.subjectGroups ?? []}
                    trialsId={record?.id}
                    organizationId={organizationId}
                  />
                </ExpandedItemSubject> */}
              </ExpandedRow>

              {/* <TabPane tab="Sessions" key="Sessions">
                  Sessions
                </TabPane> */}
            </>
          ),
          rowExpandable: () => true,
        }}
      />
      <Drawer
        title="Create New Study"
        placement="right"
        width={500}
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
        height={200}
        destroyOnClose
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <StudyForm
          onSubmit={handleCreateTrial}
          onCancel={() => setAddNew(false)}
          loading={creating}
          organizationId={organizationId}
        />
      </Drawer>
    </div>
  );
}
