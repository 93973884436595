import styled from "styled-components";
import PageSubtitle from "components/text/PageSubtitle";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Card from "components/common/Card";
import useUrlChange from "lib/hooks/useUrlChange";
import {
  DeviceAnnotationStatusEnum,
  DeviceSessionIssueTypeEnum,
  useGetStats_DeviceSessionsStatusesQuery,
  useFindSubjectStatusTotalsQuery,
  useFindSubjectIssuesTotalsQuery,
  SubjectStatusTotal,
} from "generated/graphql";
import { CaretRightOutlined } from "@ant-design/icons";
import theme from "lib/theme";
import sessionsIconHashMap from "lib/helpers/sessionsIconHashMap";
import { toNumber } from "lodash";
import sessionsIssueHashMap from "lib/helpers/sessionsIssueHashMap";
import {
  analysisStatusHashMap,
  sortedSubjectTotalStatuses,
  SubjectTotalStatusEnum,
} from "lib/helpers/analysisStatusHashMap";
import { getLabelFromStatus } from "lib/helpers/deviceStatusMap";
import { FilterChangeParam } from "pages/admin-analysis";
import Button from "components/common/Button";
import { useEffect } from "react";
import moment from "moment";

const StatsCardLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral1};
  width: 85%;
  gap: 5px;
  cursor: pointer;
`;

const StatsCardValue = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.primary4};
  text-align: right;
  width: 10%;
  cursor: pointer;
  position: relative;
  &:hover {
    color: ${({ theme }) => theme.colors.primary2};
  }
`;

const StatRow = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  background: ${({ theme, active }) => {
    if (active) return theme.colors.success10;
    return "#fff";
  }};
  &:hover {
    background: ${({ theme, active }) => {
      if (active) return theme.colors.success10;
      return theme.colors.neutral11;
    }};
  }
`;

const sessionToNumber = (sessionStatuses: string | string[]): number[] => {
  if (typeof sessionStatuses === "string") return [toNumber(sessionStatuses)];
  if (Array.isArray(sessionStatuses))
    return sessionStatuses?.map((item) => toNumber(item));
  return [];
};

interface StatsProps {
  onFiltersChange?: (params: FilterChangeParam) => void;
  issuesTypes?: Set<string>;
  statusData?: Set<string>;
  clients?: Set<string>;
  sites?: Set<string>;
  studies?: Set<string>;
  devices?: Set<string>;
  sessions?: Set<string>;
  uploads?: Set<string>;
  startDate?: Date;
  endDate?: Date;
  reload?: string;
}

const IssueStats = ({
  onFiltersChange: onFilterChange = (_) => void 0,
  issuesTypes = new Set<string>(),
  statusData = new Set<string>(),
  clients = new Set<string>(),
  sites = new Set<string>(),
  studies = new Set<string>(),
  devices = new Set<string>(),
  sessions = new Set<string>(),
  uploads = new Set<string>(),
  startDate,
  endDate,
  reload = "",
}: StatsProps) => {
  const params = {
    deviceIds: devices,
    sessionIds: sessions,
    uploadIds: uploads,
    clientIds: clients,
    siteIds: sites,
    studyIds: studies,
    statuses: statusData,
  };
  const variables: any = Object.entries(params)
    .filter(([, value]) => value.size > 0)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.from(value),
      }),
      {}
    );
  const temp = variables?.sessionIds?.map((value: string) => parseInt(value));

  const { data, refetch } = useFindSubjectIssuesTotalsQuery({
    variables: {
      ...variables,
      sessionIds: temp,
      sessionStartDate: startDate && moment(startDate).toISOString(),
      sessionEndDate: endDate && moment(endDate).toISOString(),
    },
  });

  useEffect(() => {
    refetch({ ...variables, sessionIds: temp });
  }, [reload, refetch, variables]);

  return (
    <div style={{ height: "90%" }}>
      <PageSubtitle style={{ textAlign: "center" }}>Issues</PageSubtitle>
      <Card style={{ minHeight: 310, height: "90%" }}>
        {data?.findSubjectIssuesTotals?.map((key) => {
          if (!key) return null;

          const { total, issueType } = key;
          const label =
            sessionsIssueHashMap[issueType as DeviceSessionIssueTypeEnum];
          const issueVal = issueType ?? "";
          const isActive = issuesTypes.has(issueVal);

          return (
            <StatRow
              key={key?.issueType}
              active={isActive}
              onClick={() => {
                if (isActive) {
                  issuesTypes.delete(issueVal);
                } else {
                  issuesTypes.add(issueVal);
                }
                onFilterChange({ issueTypes: Array.from(issuesTypes) });
              }}
            >
              <StatsCardLabel>{label}</StatsCardLabel>
              <StatsCardValue>{total.toLocaleString()}</StatsCardValue>
            </StatRow>
          );
        })}
      </Card>
    </div>
  );
};

interface AnnotationsStatsProps {
  onFiltersChange?: (params: FilterChangeParam) => void;
  statusData?: Set<string>;
  studies?: Set<string>;
  sites?: Set<string>;
  clients?: Set<string>;
  uploads?: Set<string>;
  devices?: Set<string>;
  sessions?: Set<string>;
  startDate?: Date;
  endDate?: Date;
  reload?: string;
}

const AnnotationStats = ({
  onFiltersChange = (_) => void _,
  statusData = new Set<string>(),
  studies = new Set<string>(),
  sites = new Set<string>(),
  clients = new Set<string>(),
  uploads = new Set<string>(),
  devices = new Set<string>(),
  sessions = new Set<string>(),
  startDate,
  endDate,
  reload = "",
}: AnnotationsStatsProps) => {
  const variables: any = Object.entries({
    clientIds: clients,
    siteIds: sites,
    studyIds: studies,
    uploadIds: uploads,
    deviceIds: devices,
    sessionIds: sessions,
  })
    .filter(([, value]) => value.size > 0)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: Array.from(value) }), {});
  const temp = variables?.sessionIds?.map((value: string) => parseInt(value));

  const { data, refetch } = useFindSubjectStatusTotalsQuery({
    variables: {
      ...variables,
      sessionIds: temp,
      sessionStartDate: startDate && moment(startDate).toISOString(),
      sessionEndDate: endDate && moment(endDate).toISOString(),
    },
  });

  const unorderedStats = data?.findSubjectStatusTotals?.map((obj) => {
    if (!obj) {
      return { index: -1, component: null };
    }

    const { key = "", label = "", total = 0 } = obj;
    const isActive = statusData.has(key);
    const icon = analysisStatusHashMap[key] ?? null;

    const component = (
      <StatRow
        key={key}
        active={isActive}
        onClick={() => {
          if (isActive) {
            statusData.delete(key);
          } else {
            statusData.add(key);
          }
          onFiltersChange({
            statusData: Array.from(statusData),
          });
        }}
      >
        <StatsCardLabel>
          {icon}
          <span style={{ width: 4, display: "inline-block" }} />
          {label}
        </StatsCardLabel>
        <StatsCardValue>{total.toLocaleString()}</StatsCardValue>
      </StatRow>
    );
    const index = sortedSubjectTotalStatuses.indexOf(
      obj?.key as SubjectTotalStatusEnum
    );
    return { component, index };
  });

  const orderedStats = unorderedStats?.sort((a, b) => a.index - b.index);

  useEffect(() => {
    refetch({ ...variables, sessionIds: temp });
  }, [reload, refetch, variables]);

  return (
    <div style={{ height: "90%" }}>
      <PageSubtitle style={{ textAlign: "center" }}>
        Analysis Status
      </PageSubtitle>
      <Card style={{ minHeight: 310, height: "90%" }}>
        {orderedStats?.map((obj) => obj?.component)}
      </Card>
    </div>
  );
};

const Carot = () => (
  <CaretRightOutlined
    style={{
      position: "absolute",
      left: 0,
      bottom: 1,
      color: theme.colors.success6,
      fontSize: 18,
    }}
  />
);

const SessionStatusStats = ({ onFiltersChange }: any) => {
  const {
    query: {
      sessionStatuses,
      issueStatuses,
      annotationStatuses,
      organizationIds,
      siteIds,
      trialIds,
      active,
    },
  } = useUrlChange();

  const { data, loading } = useGetStats_DeviceSessionsStatusesQuery({
    variables: {
      organizationIds,
      siteIds,
      trialIds,
      annotationStatuses: annotationStatuses as DeviceAnnotationStatusEnum[],
      issueStatuses: issueStatuses as DeviceSessionIssueTypeEnum[],
      active: active === "true" ? true : false,
    },
    fetchPolicy: "cache-and-network",
  });
  return (
    <div style={{}}>
      {[
        // DeviceSessionStatusEnum.NotYetProvisioned,
        "2",
        "3",
        "4",
        "5",
        "6",
      ]?.map((key) => {
        return (
          <StatRow
            key={key}
            active={sessionStatuses?.includes(key)}
            onClick={() => {
              if (sessionStatuses?.includes(key)) {
                // remove it
                const existingStatuses = [...(sessionStatuses as string[])];
                onFiltersChange({
                  sessionStatuses: existingStatuses?.filter(
                    (existinKey) => existinKey !== key
                  ),
                });
              } else {
                // add it
                onFiltersChange({
                  sessionStatuses: Array.from(
                    new Set([...((sessionStatuses || []) as string[]), key])
                  ),
                });
              }
            }}
          >
            <StatsCardLabel>
              {sessionsIconHashMap[key]}
              {getLabelFromStatus(key)}
            </StatsCardLabel>
            <StatsCardValue>
              {sessionStatuses?.includes(key) && <Carot />}
              {loading
                ? "..."
                : data?.getStats_DeviceSessionsStatuses?.find(
                    (item) => item.label === key
                  )?.value}
            </StatsCardValue>
          </StatRow>
        );
      })}
      {/* <StatRow>
          <StatsCardLabel>Total</StatsCardLabel>
          <StatsCardValue>
            {loading
              ? "..."
              : Object.values(data?.getStats_DeviceSessionsStatuses || {})
                  ?.filter((item) => typeof item !== "string")
                  .reduce((a, b) => toNumber(a) + toNumber(b), 0)}
          </StatsCardValue>
        </StatRow> */}
    </div>
  );
};

const Section = styled.section`
  .ant-row-flex,
  .ant-row {
    margin: 0 auto !important;
  }
  box-sizing: border-box !important;
`;

interface Props {
  onFiltersChange?: (filters: FilterChangeParam) => void;
  statusData?: Set<string>;
  issuesTypes?: Set<string>;
  clients?: Set<string>;
  studies?: Set<string>;
  sites?: Set<string>;
  uploads?: Set<string>;
  devices?: Set<string>;
  sessions?: Set<string>;
  startDate?: Date;
  endDate?: Date;
  subjectStatusTotals?: SubjectStatusTotal[];
  reload?: string;
}

export default function StatsRow({
  onFiltersChange = (_) => void _,
  statusData = new Set<string>(),
  issuesTypes = new Set<string>(),
  clients = new Set<string>(),
  studies = new Set<string>(),
  sites = new Set<string>(),
  uploads = new Set<string>(),
  devices = new Set<string>(),
  sessions = new Set<string>(),
  startDate,
  endDate,
  reload = "",
}: Props) {
  return (
    <Section>
      <Row
        style={{
          width: "80%",
        }}
        gutter={16}
        justify="space-between"
      >
        <Col xs={10}>
          <AnnotationStats
            key="annotation-stats"
            onFiltersChange={onFiltersChange}
            statusData={statusData}
            clients={clients}
            sites={sites}
            studies={studies}
            uploads={uploads}
            devices={devices}
            sessions={sessions}
            startDate={startDate}
            endDate={endDate}
            reload={reload}
          />
          {statusData.size > 0 && (
            <Button
              secondary={true}
              onClick={() => onFiltersChange({ statusData: undefined })}
              style={{ marginTop: "16px" }}
            >
              Clear all
            </Button>
          )}
        </Col>
        <Col xs={10}>
          <IssueStats
            onFiltersChange={onFiltersChange}
            issuesTypes={issuesTypes}
            clients={clients}
            studies={studies}
            sites={sites}
            statusData={statusData}
            reload={reload}
            uploads={uploads}
            devices={devices}
            sessions={sessions}
            startDate={startDate}
            endDate={endDate}
          />
          {issuesTypes.size > 0 && (
            <Button
              secondary={true}
              onClick={() => onFiltersChange({ issueTypes: undefined })}
              style={{ marginTop: "16px" }}
            >
              Clear all
            </Button>
          )}
        </Col>
      </Row>
    </Section>
  );
}
