import Table from "components/common/Table";
import {
  GetManySitesDocument,
  GetManySitesQueryVariables,
  useCreateSiteMutation,
  useGetManySitesQuery,
  Site,
  useUpdateSiteMutation,
} from "generated/graphql";
import Text from "components/text/Text";
import styled from "styled-components";
import Button from "components/common/Button";
import SiteForm from "components/forms/SiteForm";
import Drawer from "components/common/Drawer";
import message from "components/common/message";
import Loading from "components/common/Loading";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Caption from "components/text/Caption";
import { DocumentNode } from "graphql";
import FilterColNames from "components/common/FilterColNames";
import { SearchOutlined } from "@ant-design/icons";
import theme from "lib/theme";
import StudiesCard from "components/common/StudiesCard";
import FilterColumns from "components/common/FilterColumn";
import PrimaryButton from "components/common/Button";
import useUrlChange from "lib/hooks/useUrlChange";
import { Divider } from "antd";
import SiteStudies from "./SiteStudies";

const TitleExpandRow = styled.h3`
  color: ${({ theme }) => theme.colors.buttonSelect};
`;

const ExpandedRow = styled.div`
  display: flex;
  gap: 20px;
`;

const ExpandedItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExpandedItemSubject = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export default function Studies({
  trialsId,
  onKeyChange,
  organizationId,
}: {
  organizationId: string;
  trialsId: string;
  onKeyChange: any;
}) {
  const [addNew, setAddNew] = useState<boolean>(false);
  const { onUrlChange } = useUrlChange();
  const [editData, setEditData] = useState<Site>();
  const [edit, setEdit] = useState<boolean>(false);
  const { data, loading, error } = useGetManySitesQuery({
    variables: {
      organizationIds: organizationId ? organizationId : "",
      trialIds: trialsId,
    },
  });
  // const mapRecord =
  //   data?.getManySites?.reduce?.((acc, key: Site | any) => {
  //     const studies = key?.studies ?? [];
  //     return [...acc, ...studies];
  //   }, [] as Site[]) ?? [];

  const [createSiteMutation, { loading: creating }] = useCreateSiteMutation();
  const [updateSiteMutation, { loading: editing }] = useUpdateSiteMutation();

  const columns = [
    {
      title: "Site",
      key: "site",
      render: (record: Site) => <Text>{record.name}</Text>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColNames
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={data?.getManySites as Site[]}
            placeHolder="Search by Site"
          />
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      onFilter: (value: string, record: Site) => {
        return (
          record.name?.toLowerCase().indexOf(value.toString().toLowerCase()) ===
          0
        );
      },
    },
    {
      title: "Studies",
      key: "studies",
      align: "center",
      render: (record: Site) => (
        <Text style={{ color: "#515151" }}>{record?.studies?.length}</Text>
      ),
    },
    //   filterDropdown: ({
    //     setSelectedKeys,
    //     selectedKeys,
    //     confirm,
    //     clearFilters,
    //   }: any) => {
    //     function uniq(a: Site[]) {
    //       return Array.from(new Set(a));
    //     }
    //     return (
    //       // <FilterColNames
    //       //   setSelectedKeys={setSelectedKeys}
    //       //   selectedKeys={selectedKeys}
    //       //   confirm={confirm}
    //       //   clearFilters={clearFilters}
    //       //   dataSource={mapRecord as Site[]}
    //       //   placeHolder="Search by Study"
    //       // />
    //       <FilterColNames
    //         setSelectedKeys={setSelectedKeys}
    //         selectedKeys={
    //           trialsId
    //             ? selectedKeys.length > 0 && selectedKeys[0]
    //               ? [
    //                   ...selectedKeys,
    //                   uniq(mapRecord).find((study) => study?.id === trialsId)
    //                     ?.name,
    //                 ]
    //               : [
    //                   uniq(mapRecord).find((study) => study?.id === trialsId)
    //                     ?.name,
    //                 ]
    //             : selectedKeys
    //         }
    //         confirm={confirm}
    //         clearFilters={() => {
    //           clearFilters();
    //           onUrlChange({
    //             trialsId: null,
    //           });
    //         }}
    //         dataSource={uniq(mapRecord) as Site[]}
    //         placeHolder="Search By Study Name"
    //       />
    //     );
    //   },
    //   filterIcon: () => (
    //     <SearchOutlined
    //       style={{
    //         color: theme.colors.gray2,
    //         fontSize: 18,
    //       }}
    //     />
    //   ),
    //   onFilter: (value: string, record: Site) => {
    //     const mapRecordFilter =
    //       record?.studies?.reduce?.((_, key: string | any) => {
    //         return key?.name;
    //       }, "" as string) ?? "";
    //     return mapRecordFilter
    //       .toString()
    //       .toLowerCase()
    //       .includes(value.toLowerCase());
    //   },
    // },
    {
      title: "Contact Name",
      key: "contactName",
      render: (record: Site) => <Text>{record.contactName}</Text>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColumns
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={data?.getManySites as Site[]}
            placeHolder="Search By Contact Name"
          />
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      onFilter: (value: string, record: Site) => {
        return (
          record.contactName
            ?.toLowerCase()
            .indexOf(value.toString().toLowerCase()) === 0
        );
      },
    },
    // {
    //   title: "Address",
    //   with: "25%",
    //   render: (record: Site) => (
    //     <>
    //       <Text>{`${record.streetAddress1 ?? ""} `}</Text>
    //       <Text>
    //         {`
    //       ${record.streetAddress2 ?? ""}
    //     `}
    //       </Text>
    //       <Text>
    //         {`
    //       ${record.city ?? ""}
    //      `}
    //       </Text>
    //       <Text>
    //         {`
    //       ${record.state ?? ""}
    //      `}
    //       </Text>
    //       <Text>
    //         {`
    //       ${record.zipCode ?? ""}
    //      `}
    //       </Text>
    //       <Text>
    //         {`
    //       ${record.country ?? ""}`}
    //       </Text>
    //     </>
    //   ),
    // },
    {
      title: "Phone Number",
      render: (record: Site) => <Text>{record.phone}</Text>,
    },
    {
      title: "Email",
      with: "25%",
      render: (record: Site) => <Text>{record.email}</Text>,
    },
    {
      title: "Edit",
      render: (record: Site) => (
        <div onClick={(e) => e.stopPropagation()}>
          <PrimaryButton
            onClick={() => {
              setAddNew(true);
              setEditData(record);
              setEdit(true);
            }}
          >
            Edit
          </PrimaryButton>
        </div>
      ),
    },
  ];

  const handleCreateSite = useCallback(
    async (values: {
      id?: string;
      name?: string;
      contactName?: string | null;
      phone?: string | null;
      email?: string | null;
      streetAddress1?: string | null;
      streetAddress2?: string | null;
      city?: string | null;
      state?: string | null;
      zipCode?: string | null;
      country?: string | null;
      studies?: string[] | null;
    }) => {
      try {
        const refetchTrials: {
          query: DocumentNode;
          variables: GetManySitesQueryVariables;
        } = {
          query: GetManySitesDocument,
          variables: {
            organizationIds: organizationId ? organizationId : "",
            trialIds: trialsId,
          },
        };

        if (!edit) {
          await createSiteMutation({
            variables: {
              input: {
                clientId: organizationId,
                name: values?.name,
                contactName: values?.contactName,
                phone: values?.phone,
                email: values?.email,
                streetAddress1: values?.streetAddress1,
                streetAddress2: values?.streetAddress2,
                city: values?.city,
                state: values?.state,
                zipCode: values?.zipCode,
                country: values?.country,
                studies: values?.studies,
              },
            },
            refetchQueries: [refetchTrials],
          });
          setAddNew(false);
          message.success("New Site added.");
        } else {
          await updateSiteMutation({
            variables: {
              input: {
                id: editData?.id as string,
                name: values?.name,
                contactName: values?.contactName,
                phone: values?.phone,
                email: values?.email,
                streetAddress1: values?.streetAddress1,
                streetAddress2: values?.streetAddress2,
                city: values?.city,
                state: values?.state,
                zipCode: values?.zipCode,
                country: values?.country,
                studies: values?.studies,
              },
            },
            refetchQueries: [refetchTrials],
          });
          setAddNew(false);
          message.success("Site edited.");
        }
      } catch (err) {
        const msg = edit ? "Site edit failed." : "Site creation failed.";
        message.error(msg);
        console.log(err);
      } finally {
      }
    },
    [
      createSiteMutation,
      updateSiteMutation,
      organizationId,
      trialsId,
      edit,
      editData,
    ]
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Caption>Something went wrong</Caption>;
  }

  return (
    <div>
      <Button
        onClick={() => {
          setAddNew(true);
          setEdit(false);
        }}
        style={{ margin: 16, marginLeft: 0, width: 130, height: "33px" }}
      >
        Add Site
      </Button>
      <Table
        dataSource={data?.getManySites as Site[]}
        columns={columns?.map((item: any) => item)}
        rowKey="id"
        className="tableClass"
        expandRowByClick
        expandable={{
          expandedRowRender: (record: Site) => (
            <>
              <ExpandedRow>
                <ExpandedItem style={{ width: "25%" }}>
                  <TitleExpandRow>Studies:</TitleExpandRow>
                  <SiteStudies
                    selectStudy={({ studyId }: { studyId: string }) => {
                      onUrlChange({
                        studyId: studyId,
                      });
                      onKeyChange("2");
                    }}
                    studies={record?.studies}
                  />
                </ExpandedItem>
                <Divider
                  type="vertical"
                  style={{
                    height: "265px",
                  }}
                />
                <ExpandedItemSubject>
                  <TitleExpandRow> Address</TitleExpandRow>
                  <>
                    <Text>{`${record.streetAddress1 ?? ""} `}</Text>
                    <Text>{`${record.streetAddress2 ?? ""} `}</Text>
                    <Text>{`${record.city ?? ""} `}</Text>
                    <Text>{`${record.state ?? ""} `}</Text>
                    <Text>{`${record.zipCode ?? ""} `}</Text>
                    <Text>{`${record.country ?? ""} `}</Text>
                  </>
                </ExpandedItemSubject>
              </ExpandedRow>

              {/* <TabPane tab="Sessions" key="Sessions">
                  Sessions
                </TabPane> */}
            </>
          ),
          rowExpandable: () => true,
        }}
      />
      <Drawer
        title={edit ? `Edit Site ${editData?.name}` : "Create New Site"}
        onClose={() => setAddNew(false)}
        visible={addNew ? true : false}
      >
        <SiteForm
          onSubmit={handleCreateSite}
          onCancel={() => setAddNew(false)}
          loading={creating || editing}
          editData={editData}
          edit={edit}
          organizationId={organizationId}
        />
      </Drawer>
    </div>
  );
}
