import { Table, Row, Col, message } from "antd";
import PageTitle from "components/text/PageTitle";
import theme from "lib/theme";
import { Key, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatRef,
  TABLE_COLUMNS,
  transformSessionsToModels,
} from "components/tables/AnalysisSessionTable/data";
import { AdjustTimeCard } from "components/tables/AnalysisSessionTable/adjust-time-card";
import { SessionUpdateLogCard } from "components/tables/AnalysisSessionTable/session-update-log-card";
import { IssuesCard } from "components/tables/AnalysisSessionTable/issues-card";
import IssueModal from "components/common/SessionsTable/IssueModal";
import {
  Base64File,
  DeviceSessionIssue,
  GetSessionReportFilesQuery,
  GetSessionReportFilesQueryVariables,
  Session,
  SessionReference,
  SessionStatusEnum,
  Study,
  UpdateSessionMutation,
  UpdateSessionMutationVariables,
  UpdateSessionReferenceMutation,
  UpdateSessionReferenceMutationVariables,
  UpdateSessionReviewedMutation,
  UpdateSessionReviewedMutationVariables,
} from "generated/graphql";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import { format } from "date-fns";
import { gql, useApolloClient } from "@apollo/client";
import getSessionReportFiles from "ApolloClient/Queries/getSessionReportFiles";
import { downloadBlob } from "lib/helpers/downloadBlob";
import {
  updateSessionReference,
  updateSessionSelected,
} from "ApolloClient/Mutations/updateSession";
import { SubjectTotalStatusEnum } from "lib/helpers/analysisStatusHashMap";
import moment from "moment";

interface ExpandedRowCardsProps {
  deviceStartTime: Date;
  deviceStopTime: Date;
  issues: DeviceSessionIssue[];
  storage: string;
  hardware: string;
  firmware: string;
  tasks: number;
  completed: number;
  upload: string;
  sessionId: string;
  sessionStartTime: Date;
  sessionStopTime: Date;
  reloadQuery: any;
}

const ExpandedRowCards = ({
  deviceStartTime,
  deviceStopTime,
  issues,
  storage,
  hardware,
  firmware,
  tasks,
  completed,
  upload,
  sessionId,
  sessionStartTime,
  sessionStopTime,
  reloadQuery,
}: ExpandedRowCardsProps) => (
  <div style={{ marginBottom: "13px" }}>
    <Row gutter={9}>
      <Col span={8}>
        <AdjustTimeCard
          deviceStartTime={deviceStartTime}
          deviceStopTime={deviceStopTime}
          sessionStartTime={sessionStartTime}
          sessionStopTime={sessionStopTime}
          storage={storage}
          hardware={hardware}
          firmware={firmware}
          upload={upload}
          sessionId={sessionId}
        />
      </Col>
      <Col span={8}>
        <SessionUpdateLogCard
          tasks={tasks}
          completed={completed}
          deviceSessionId={sessionId}
        />
      </Col>
      <Col span={8}>
        <IssuesCard
          issues={issues}
          deviceSessionId={sessionId}
          reloadQuery={reloadQuery}
        />
      </Col>
    </Row>
  </div>
);

export interface SelectReferenceParam {
  session: string;
  reference: string | boolean;
}

interface InitialMutators {
  selectedSessionsMemoed: Record<string, boolean>;
  reviewedSessionsMemoed: Record<string, boolean>;
  takenReferencesMemoed: Record<string, boolean | string>;
}

export interface Props {
  sessions: Session[];
  studyData: Study;
  subjectId: string;
  sessionUploadId: string;
  reloadQuery?: any;
}

export const AnalysisSessionTable = ({
  sessions,
  studyData,
  subjectId,
  sessionUploadId,
  reloadQuery,
}: Props) => {
  const searchParams = new URLSearchParams(window.location.search);

  const {
    selectedSessionsMemoed,
    reviewedSessionsMemoed,
    takenReferencesMemoed,
  } = useMemo(
    () =>
      sessions.reduce(
        (
          {
            selectedSessionsMemoed,
            reviewedSessionsMemoed,
            takenReferencesMemoed,
          },
          session
        ) => {
          selectedSessionsMemoed[session.id] = session.selected ?? false;
          reviewedSessionsMemoed[session.id] =
            session.statusKey === SessionStatusEnum.Reviewed;
          takenReferencesMemoed[session.id] = !!session.reference
            ? formatRef(session.reference)
            : false;
          return {
            selectedSessionsMemoed,
            reviewedSessionsMemoed,
            takenReferencesMemoed,
          };
        },
        {
          selectedSessionsMemoed: {},
          reviewedSessionsMemoed: {},
          takenReferencesMemoed: {},
        } as InitialMutators
      ),
    [sessions]
  );
  const [selectedSessions, setSelectedSessions] = useState(
    selectedSessionsMemoed
  );
  const [reviewedSessions, setReviewedSessions] = useState(
    reviewedSessionsMemoed
  );
  const [takenReferences, setTakenReferences] = useState(takenReferencesMemoed);
  const [reportIssueModalOpen, setReportIssueModalOpen] =
    useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>("");
  const data = useMemo(
    () => transformSessionsToModels(sessions, studyData),
    [sessions, studyData]
  );
  const onReportClick = useCallback((session: string) => {
    setSessionId(session);
    setReportIssueModalOpen(true);
  }, []);
  const [downloadFile] = useDownloadCSV();
  const onDownloadSessions = useCallback(() => {
    const records = data.map(
      ({
        session,
        status,
        progress,
        device,
        selected,
        reviewed,
        reference,
        sessionStart: sessionStartTime,
        sessionStop: sessionStopTime,
        issues,
        storage,
        hardware: hardwareVersion,
        firmware: firmwareVersion,
        totalTasks,
        completedTasks,
        upload: uploadId,
      }) => ({
        session,
        uploadId,
        status,
        totalTasks,
        completedTasks,
        progress: `${progress.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}%`,
        storage: `${storage?.split?.("%")?.[0] ?? 0}% Full`,
        device,
        selected: selected ? 1 : 0,
        reviewed: reviewed ? 1 : 0,
        reference: `${reference?.prefix ?? ""}${reference?.day ?? ""}`,
        sessionStartTime,
        sessionStopTime,
        issues: issues.length,
        hardwareVersion,
        firmwareVersion,
      })
    );
    downloadFile(
      records,
      `SessionSummary-${subjectId}-${format(
        Date.now(),
        "yyyy-MM-dd'T'HH-mm-ss"
      )}.csv`
    );
  }, [downloadFile, data]);

  const apolloClient = useApolloClient();

  const onDownloadSession = useCallback(
    (sessionId: string) => {
      (async () => {
        const messageKey = `download-session-report-${sessionId}`;
        try {
          message.loading({
            content: "Downloading session report...",
            key: messageKey,
            duration: 0,
          });

          const { data } = await apolloClient.query<
            GetSessionReportFilesQuery,
            GetSessionReportFilesQueryVariables
          >({
            query: getSessionReportFiles,
            variables: {
              sessionId,
            },
          });

          const { content = "", fileName = "" } =
            data.getSessionReportFiles ?? ({} as Base64File);

          if (content.length === 0) {
            message.warn({
              content: "Report files are not available.",
              key: messageKey,
              duration: 6,
            });
            return;
          }

          const url = `data:application/zip;base64,${content}`;
          const raw = await fetch(url);
          const blob = await raw.blob();
          downloadBlob(blob, fileName);
          message.success({
            content: "Report Files downloaded.",
            key: messageKey,
            duration: 3,
          });
        } catch (error) {
          message.error({
            content: "There was an error when fetching session report files.",
            key: messageKey,
            duration: 6,
          });
        }
      })();
    },
    [apolloClient]
  );

  const onSelectedSessionsChange = useCallback(
    (sessionId: string, selected: boolean) => {
      (async () => {
        const messageKey = `select-session-${sessionId}`;
        const selectingOrDeselecting = selected ? "Selecting" : "Deselecting";
        const pastSelectedOrDeSelected = selected ? "selected" : "deselected";
        setSelectedSessions((sel) => {
          sel[sessionId] = selected;
          return { ...sel };
        });

        message.loading({
          content: `${selectingOrDeselecting} session...`,
          key: messageKey,
          duration: 0,
        });

        apolloClient.writeFragment({
          id: `Session:${sessionId}`,
          fragment: gql`
            fragment SessionSelected on Session {
              selected
            }
          `,
          data: {
            selected,
          },
        });

        try {
          await apolloClient.mutate<
            UpdateSessionMutation,
            UpdateSessionMutationVariables
          >({
            mutation: updateSessionSelected,
            variables: {
              input: {
                id: sessionId,
                selected,
              },
            },
          });
          message.success({
            content: `Session ${pastSelectedOrDeSelected}.`,
            key: messageKey,
            duration: 1,
          });
        } catch (error) {
          setSelectedSessions((sel) => {
            sel[sessionId] = !selected;
            return { ...sel };
          });
          message.warning({
            content: `There was a problem when ${selectingOrDeselecting.toLowerCase()} session.`,
            key: messageKey,
            duration: 3,
          });
          apolloClient.writeFragment({
            id: `Session:${sessionId}`,
            fragment: gql`
              fragment SessionSelected on Session {
                selected
              }
            `,
            data: {
              selected: !selected,
            },
          });
        }
      })();
    },
    [setSelectedSessions, apolloClient]
  );

  const onReviewedSessionsChange = useCallback(
    (sessionId: string, reviewed: boolean) => {
      (async () => {
        const messageKey = `review-session-${sessionId}`;
        const reviewedOrUnderReview = reviewed ? "reviewed" : "under review";
        setReviewedSessions((rev) => {
          rev[sessionId] = reviewed;
          return { ...rev };
        });

        message.loading({
          content: `Marking session as ${reviewedOrUnderReview}...`,
          key: messageKey,
          duration: 0,
        });

        apolloClient.writeFragment({
          id: `Session:${sessionId}`,
          fragment: gql`
            fragment SessionReviewed on Session {
              status
            }
          `,
          data: {
            status: {
              key: reviewed
                ? SubjectTotalStatusEnum.Reviewed
                : SubjectTotalStatusEnum.UnderReview,
            },
          },
        });

        try {
          await apolloClient.mutate<
            UpdateSessionReviewedMutation,
            UpdateSessionReviewedMutationVariables
          >({
            mutation: updateSessionSelected,
            variables: {
              input: {
                id: sessionId,
                statusKey: reviewed
                  ? SessionStatusEnum.Reviewed
                  : SessionStatusEnum.UnderReview,
              },
            },
          });
          message.success({
            content: `Session marked as ${reviewedOrUnderReview}.`,
            key: messageKey,
            duration: 1,
          });
        } catch (error) {
          setReviewedSessions((rev) => {
            rev[sessionId] = !reviewed;
            return { ...rev };
          });
          message.warning({
            content: `There was a problem when marking session as ${reviewedOrUnderReview}.`,
            key: messageKey,
            duration: 3,
          });
          apolloClient.writeFragment({
            id: `Session:${sessionId}`,
            fragment: gql`
              fragment SessionReviewed on Session {
                status
              }
            `,
            data: {
              status: {
                key: !reviewed
                  ? SubjectTotalStatusEnum.Reviewed
                  : SubjectTotalStatusEnum.UnderReview,
              },
            },
          });
        }
      })();
    },
    [setReviewedSessions, apolloClient]
  );

  const onSelectReference = useCallback(
    ({ session, reference }: SelectReferenceParam) => {
      setTakenReferences((handling) => ({ ...handling, [session]: reference }));
      const messageKey = `select-reference-${session}`;
      message.loading({
        content: "Selecting reference...",
        key: messageKey,
        duration: 0,
      });

      let fragmentReference: SessionReference | null = null;
      if (typeof reference === "string") {
        fragmentReference = JSON.parse(reference) as SessionReference;
        fragmentReference.sessionId = session;
      }
      const previousSessionReference = apolloClient.readFragment<Session>({
        id: `Session:${session}`,
        fragment: gql`
          fragment SessionReference on Session {
            reference {
              day
              prefix
            }
          }
        `,
      });
      const previousReference = previousSessionReference?.reference ?? null;
      apolloClient.writeFragment({
        id: `Session:${session}`,
        fragment: gql`
          fragment SessionReference on Session {
            reference
          }
        `,
        data: {
          reference: fragmentReference,
        },
      });

      (async () => {
        try {
          await apolloClient.mutate<
            UpdateSessionReferenceMutation,
            UpdateSessionReferenceMutationVariables
          >({
            mutation: updateSessionReference,
            variables: {
              input: {
                id: session,
                reference: fragmentReference,
              },
            },
          });
          message.success({
            content: "Reference changed.",
            key: messageKey,
            duration: 1,
          });
        } catch (error) {
          message.error({
            content: "There was a problem when changing reference.",
            key: messageKey,
            duration: 3,
          });
          setTakenReferences((handling) => ({
            ...handling,
            [session]:
              previousReference !== null ? formatRef(previousReference) : false,
          }));
          apolloClient.writeFragment({
            id: `Session:${session}`,
            fragment: gql`
              fragment SessionReference on Session {
                reference
              }
            `,
            data: {
              reference: previousReference,
            },
          });
        }
      })();
    },
    [setTakenReferences, apolloClient]
  );

  const tableColumns = useMemo(
    () =>
      TABLE_COLUMNS({
        selectedSessions,
        reviewedSessions,
        onSelectedSessionsChange,
        onReviewedSessionsChange,
        onReportClick,
        onDownloadSession,
        onSelectReference,
        takenReferences,
        onDownloadSessions,
      }),
    [
      selectedSessions,
      reviewedSessions,
      onSelectedSessionsChange,
      onReviewedSessionsChange,
      onReportClick,
      onDownloadSession,
      onSelectReference,
      takenReferences,
      onDownloadSessions,
    ]
  );

  useEffect(() => {
    setSelectedSessions(selectedSessionsMemoed);
    setReviewedSessions(reviewedSessionsMemoed);
    setTakenReferences(takenReferencesMemoed);
  }, [selectedSessionsMemoed, reviewedSessionsMemoed, takenReferencesMemoed]);
  return (
    <div>
      <PageTitle color={theme.colors.primary4}>Analysis Sessions</PageTitle>
      <Table
        columns={tableColumns}
        dataSource={data?.sort(
          (a, b) =>
            moment(a.sessionStart).unix() - moment(b.sessionStart).unix()
        )}
        size="small"
        expandRowByClick
        expandable={{
          defaultExpandedRowKeys: [
            sessionUploadId !== ""
              ? (sessionUploadId as Key)
              : (searchParams.get("sessionUploadId") as Key),
          ],
          expandedRowRender: (record) => (
            <ExpandedRowCards
              reloadQuery={reloadQuery}
              key={record.session}
              deviceStartTime={record.deviceStartTime}
              deviceStopTime={record.deviceStopTime}
              issues={record.issues}
              storage={record.storage}
              hardware={record.hardware}
              firmware={record.firmware}
              tasks={record.totalTasks}
              completed={record.completedTasks}
              upload={record.upload}
              sessionId={record.id}
              sessionStartTime={record.sessionStart}
              sessionStopTime={record.sessionStop}
            />
          ),
        }}
      />
      {/* Modal for reporting an issue about a device session */}
      <IssueModal
        visible={reportIssueModalOpen}
        onCancel={() => setReportIssueModalOpen(false)}
        sessionId={sessionId}
        subjectIdToDisplay={subjectId}
      />
    </div>
  );
};
