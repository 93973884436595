import styled from "styled-components";
// COMPONENTS

import {
  SubjectGroup,
  SubjectGroupInput,
  useGetManySitesQuery,
  useUpdateStudyMutation,
} from "generated/graphql";

import { AutoComplete, Col, message, Row, Select } from "antd";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import PrimaryButton from "components/common/Button";
import { CloseCircleFilled, DownloadOutlined } from "@ant-design/icons";
import { gql, useApolloClient } from "@apollo/client";
import TruncateText from "components/common/TruncateText";
import { onNumberFieldKeydown } from "lib/helpers/numericInputFields";
import moment from "moment";

const Input = styled.input`
  width: 90px;
  height: 25px;
  padding: 5px;
  background: #fff;
  border: 1px solid #bab9b9;
  border-radius: 7px;
`;

const RefTitle = styled.span<{ fullWidth?: boolean }>`
  font-size: 12px;
  font-weight: 300;
  color: "#BAB9B9";
`;

export type SubjectStudiesTypes = {
  __typename?: "SubjectStudiesTypes";
  id: string;
  prefix?: string;
  digits?: string[] | any;
  count?: string;
  start?: string;
  site?: boolean;
  createdAt?: Date;
};
export default function SubjetsStudies({
  defaultValues,
  trialsId,
  organizationId,
}: {
  defaultValues?: SubjectGroup[];
  trialsId: string;
  organizationId?: string;
}) {
  const [updateStudyMutation] = useUpdateStudyMutation();
  const { data } = useGetManySitesQuery({
    variables: {
      organizationIds: organizationId ? organizationId : "",
      trialIds: trialsId,
    },
  });
  const apolloClient = useApolloClient();
  const { clientName, studyName } = useMemo(() => {
    const client = apolloClient.readFragment({
      id: `Client:${organizationId}`,
      fragment: gql`
        fragment ClientName on Client {
          name
        }
      `,
    });

    const study = apolloClient.readFragment({
      id: `Trial:${trialsId}`,
      fragment: gql`
        fragment StudyName on Trial {
          name
        }
      `,
    });

    return {
      clientName: client?.name,
      studyName: study?.name,
    };
  }, [apolloClient, organizationId, trialsId]);

  const sortedSubjects = useMemo(() => {
    const working = [...(defaultValues ?? [])];
    return working.reverse();
  }, [defaultValues]);

  const Option = Select.Option;

  const [prefix, setPrefix] = useState<string>();
  const [digits, setDigits] = useState<string>();
  const [count, setCount] = useState<string>();
  const [site, setSite] = useState<string>();
  const [start, setStart] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState(true);

  const exportData = useCallback(
    (subject: SubjectGroup) => {
      const { prefix, count, digits, site, start } = subject;
      const subjects = [];

      for (let i = 1, j = start ?? 0; i <= (count ?? 0); i++) {
        const num = (j++).toString().padStart(digits ?? 0, "0");
        subjects.push(`${prefix}${num}`);
      }

      const data = {
        clientName,
        studyName,
        siteName: site?.name,
        subjects,
      };

      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data, null, 2)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "selectedSubjects.json";
      link.click();
      link.remove();
    },
    [clientName, studyName]
  );

  const handleDelete = async (id: SubjectGroup) => {
    const array = defaultValues?.filter((element) => {
      return element !== id;
    });

    try {
      await updateStudyMutation({
        variables: {
          input: {
            id: trialsId,
            subjectGroups: [
              ...(array?.map((item) => {
                return {
                  prefix: item.prefix,
                  start: item.start,
                  digits: item.digits,
                  count: item.count,
                  site: item?.site?.id,
                  createdAt: item?.createdAt,
                };
              }) as SubjectGroupInput[]),
            ],
          },
        },
      });

      message.success("Subject deleted.");
    } catch (err) {
      console.log(err);
      message.error("There was an error deleting the subject.");
    }
  };
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!prefix || !site || !start || !digits || !count) {
      return;
    }

    try {
      setDisableSubmit(true);
      await updateStudyMutation({
        variables: {
          input: {
            id: trialsId,
            subjectGroups: [
              ...(defaultValues?.map((item) => {
                return {
                  prefix: item.prefix,
                  start: item.start,
                  digits: item.digits,
                  count: item.count,
                  site: item?.site?.id,
                  createdAt: item?.createdAt,
                };
              }) as SubjectGroupInput[]),
              {
                prefix,
                start: parseInt(start),
                digits: parseInt(digits),
                count: parseInt(count),
                site,
              },
            ] as SubjectGroupInput[],
          },
        },
      });
      setDigits("");
      setCount("");
      setStart("");
      setSite("");
      setPrefix("");
      message.success("Your changes were saved");
    } catch (err) {
      console.log(err);
      message.error("There was an error saving your changes");
    }
  };

  const siteName = useMemo(() => {
    if (!site) {
      return "";
    }

    return (
      data?.getManySites?.find((item) => {
        return item.id === site;
      })?.name ?? ""
    );
  }, [data?.getManySites, site]);

  useEffect(() => {
    const atLeastOneExp = /^[1-9]\d*$/;
    const isNumber = /^\d+$/;
    const shouldDisable =
      !prefix ||
      !site ||
      !atLeastOneExp.test(digits ?? "") ||
      !atLeastOneExp.test(count ?? "") ||
      !isNumber.test(start ?? "");
    setDisableSubmit(shouldDisable);
  }, [prefix, digits, count, site, start]);

  return (
    <div style={{ height: "160px" }}>
      <Row style={{ maxWidth: "100%" }}>
        <Col span={4} style={{ textAlign: "left" }}>
          <h3 style={{ fontSize: 15 }}>Date</h3>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Prefix</h3>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Digits</h3>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Start</h3>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Counts</h3>
        </Col>
        <Col span={4} style={{ textAlign: "center" }}>
          <h3 style={{ fontSize: 15 }}>Site</h3>
        </Col>
        <Col span={2} style={{ textAlign: "center" }}></Col>
        <Col span={2} style={{ textAlign: "center" }}></Col>
      </Row>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row style={{ maxWidth: "100%" }}>
          <Col span={4} style={{ textAlign: "left" }}>
            <RefTitle>New:</RefTitle>
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            <Input
              value={prefix}
              type="text"
              required
              onChange={(e) => {
                setPrefix(e.target.value);
              }}
              style={{
                textAlign: "center",
                maxWidth: "80%",
              }}
            />
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            <Input
              value={digits}
              onChange={(e) => setDigits(e.currentTarget.value)}
              style={{
                width: "50px",
                height: "25px",
                borderRadius: "7px",
                textAlign: "center",
              }}
              min={1}
              type="text"
              onKeyDown={onNumberFieldKeydown}
            />
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            <Input
              value={start}
              onChange={(e) => setStart(e.currentTarget.value)}
              style={{
                width: "50px",
                height: "25px",
                textAlign: "center",
                borderRadius: "7px",
              }}
              type="text"
              min={0}
              onKeyDown={onNumberFieldKeydown}
            />
          </Col>
          <Col span={3} style={{ textAlign: "center" }}>
            <Input
              value={count}
              onChange={(e) => setCount(e.currentTarget.value)}
              style={{
                width: "50px",
                height: "25px",
                textAlign: "center",
                borderRadius: "7px",
              }}
              type="text"
              min={1}
              onKeyDown={onNumberFieldKeydown}
            />
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <AutoComplete
              value={siteName}
              size="small"
              onChange={(value: string) => setSite(value)}
              style={{
                width: "80%",
                textAlign: "center",
                borderRadius: "7px",
              }}
            >
              {data?.getManySites?.map((sel) => (
                <Option
                  key={sel.name}
                  value={sel.id}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {sel.name}
                </Option>
              ))}
            </AutoComplete>
          </Col>
          <Col span={4} style={{ textAlign: "center" }}>
            <PrimaryButton type="Submit" disabled={disableSubmit}>
              Add
            </PrimaryButton>
          </Col>
        </Row>
      </form>
      <div style={{ height: "96px", overflowY: "auto", marginTop: "5px" }}>
        {sortedSubjects.map((subject) => {
          return (
            <Row
              style={{ maxWidth: "100%", paddingTop: 4, paddingBottom: 4 }}
              key={`${subject.createdAt}${subject.prefix}${subject.digits}${subject.start}${subject.count}${subject?.site?.id}`}
            >
              <Col
                span={4}
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                <TruncateText
                  text={moment(subject.createdAt).format("MM/DD/YY, hh:mm a")}
                >
                  <span>{moment(subject.createdAt).format("MM/DD/YY")}</span>
                </TruncateText>
              </Col>
              <Col
                span={3}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {subject.prefix}
              </Col>
              <Col
                span={3}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {subject.digits}
              </Col>
              <Col
                span={3}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {subject.start}
              </Col>
              <Col
                span={3}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {subject.count}
              </Col>
              <Col
                span={4}
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                {subject.site?.name}
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                <DownloadOutlined onClick={() => exportData(subject)} />
              </Col>
              <Col
                span={2}
                style={{ textAlign: "center", color: "gray", fontSize: 12 }}
              >
                <CloseCircleFilled onClick={() => handleDelete(subject)} />
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}
