import { DeviceSessionIssueTypeEnum } from "generated/graphql";

const sessionsIssueHashMap: any = {
  [DeviceSessionIssueTypeEnum.ClientReported]: "Client Issue",
  [DeviceSessionIssueTypeEnum.ParticipantReported]: "Participant Issue",
  [DeviceSessionIssueTypeEnum.DeviceIssue]: "Device Issue",
  [DeviceSessionIssueTypeEnum.UploadIssue]: "Upload Issue",
  [DeviceSessionIssueTypeEnum.OtherIssue]: "Other Issue",
};

export default sessionsIssueHashMap;
