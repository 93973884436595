import { Link } from "react-router-dom";
import styled from "styled-components";
// LIB
import logo from "lib/media/logo-horizontal.png";
import AvatarDropdown from "./AvatarDropdown";
import Tag from "components/common/Tag";

const AppNavContainer = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
  background: #fff;
`;

const NavItemContainer = styled.div<{ active: boolean }>`
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 16px !important;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  a {
    color: ${(p) =>
      p.active ? p.theme.colors.primary3 : p.theme.colors.neutral6} !important;
  }
  a:hover {
    color: ${(p) => p.theme.colors.primary3} !important;
  }
`;

const ActiveBar = styled.div<{ active: boolean }>`
  height: 6px;
  width: 100%;
  position: relative;
  bottom: -11px;
  background: ${(p) => (p.active ? p.theme.colors.primary3 : "transparent")};
`;

const NavItem = ({ children, active, to }: any) => (
  <div>
    <NavItemContainer active={active}>
      <Link to={to} style={{ fontSize: 18 }}>
        {children}
      </Link>
    </NavItemContainer>
    <ActiveBar active={active} />
  </div>
);

const MobileCol = styled.div`
  display: inline-flex !important;
  margin-left: 32px;
`;

const RightContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 19px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    display: none !important;
  }
`;

export default function AppNav({ pathname }: any) {
  const NAV_ITEMS = [
    {
      name: "SESSIONS",
      to: "/admin/sessions",
      active: pathname && pathname.includes("/admin/sessions"),
    },
    {
      name: "ANALYSIS",
      to: "/admin/analysis",
      active:
        pathname &&
        (pathname.includes("/admin/analysis") || pathname === "/admin"),
    },
    {
      name: "CLIENTS",
      to: "/admin/clients",
      active: pathname && pathname.includes("/admin/clients"),
    },
    {
      name: "USERS",
      to: "/admin/users",
      active: pathname && pathname.includes("/admin/users"),
    },
    // {
    //   name: "LOGS",
    //   to: "/admin/logs",
    //   active: pathname && pathname.includes("/admin/logs"),
    // },
  ];

  return (
    <>
      <AppNavContainer>
        <div
          style={{
            height: 72,
            margin: "auto",
            width: 1400,
            maxWidth: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Link to="/admin/sessions">
              <img
                src={logo}
                height="36"
                alt="logo"
                style={{ marginBottom: 0 }}
              />
            </Link>
            <Tag
              color="cyan"
              style={{ position: "relative", left: 6, bottom: -2 }}
            >
              Admin Portal
            </Tag>
          </div>
          <RightContainer>
            {NAV_ITEMS?.map((item) => {
              return (
                <MobileCol key={item.name}>
                  <NavItem to={item.to} active={item.active}>
                    {item.name}
                  </NavItem>
                </MobileCol>
              );
            })}
            <div style={{ marginLeft: 56 }}>
              <AvatarDropdown />
            </div>
          </RightContainer>
        </div>
      </AppNavContainer>
    </>
  );
}
