import { TableColumnsType, Checkbox, Select, Switch, Tooltip } from "antd";
import PrimaryButton from "components/common/Button";
import { format, parseISO } from "date-fns";
import {
  DeviceAnnotationStatusEnum,
  DeviceSessionIssue,
  Session,
  SessionReference,
  SessionStatusEnum,
  Study,
} from "generated/graphql";
import { IconByStatus } from "components/tables/AnalysisSessionTable/util";
import { SubjectTotalStatusEnum } from "lib/helpers/analysisStatusHashMap";
import { SelectReferenceParam } from "components/tables/AnalysisSessionTable";
import { DownloadOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";

export interface AnalysisSessionModel {
  id: string;
  session: string;
  status: SubjectTotalStatusEnum;
  device: string;
  reviewed: boolean;
  reference: SessionReference;
  sessionStart: Date;
  sessionStop: Date;
  issues: DeviceSessionIssue[];
  availableReferences: SessionReference[];
  progress: number;
  completedTasks: number;
  totalTasks: number;
  firmware: string;
  hardware: string;
  storage: string;
  upload: string;
  selected: boolean;
  deviceStartTime: Date;
  deviceStopTime: Date;
}

export const DATE_DISPLAY_FORMAT = "MM/dd/yy h:mm a";

// This function is needed to be pretty sure we keep
// the order of the reference's fields.
export const formatRef = (refer: SessionReference) =>
  !!refer &&
  JSON.stringify({
    prefix: refer.prefix,
    day: refer.day,
  });

interface TableColumnsProps {
  selectedSessions: Record<string, boolean>;
  reviewedSessions: Record<string, boolean>;
  onSelectedSessionsChange: (session: string, value: boolean) => void;
  onReviewedSessionsChange: (session: string, value: boolean) => void;
  onReportClick: (session: string) => void;
  onDownloadSession: (session: string) => void;
  onSelectReference: (_: SelectReferenceParam) => void;
  takenReferences: Record<string, boolean | string>;
  onDownloadSessions?: () => void;
}

export const TABLE_COLUMNS: (
  p: TableColumnsProps
) => TableColumnsType<AnalysisSessionModel> = ({
  selectedSessions,
  reviewedSessions,
  onSelectedSessionsChange,
  onReviewedSessionsChange,
  onReportClick,
  onDownloadSession = (_) => void _,
  onSelectReference = (_) => void _,
  takenReferences,
  onDownloadSessions = () => void 0,
}: TableColumnsProps) => [
  {
    title: "Session",
    dataIndex: "session",
    key: "session",
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: SubjectTotalStatusEnum) => {
      return IconByStatus?.[status]?.({}) ?? "Invalid status";
    },
    align: "center",
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    render: (progress: number = 0) => `${Math.floor(progress).toFixed(0)}%`,
    align: "center",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
    align: "center",
  },
  {
    title: "Select",
    dataIndex: "",
    render: (_, { id }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={selectedSessions[id]}
          onChange={(e) => onSelectedSessionsChange(id, e.target.checked)}
        />
      </div>
    ),
    align: "center",
  },
  {
    title: "Reviewed",
    dataIndex: "reviewed",
    key: "reviewed",
    render: (_, { id, status }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Switch
          checked={reviewedSessions[id]}
          onChange={(e) => {
            onReviewedSessionsChange(id, e);
          }}
          disabled={
            [
              SubjectTotalStatusEnum.Reviewed,
              SubjectTotalStatusEnum.UnderReview,
            ].indexOf(status) === -1
          }
        />
      </div>
    ),
    align: "center",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
    render: (_, { reference, availableReferences, id: session }) => (
      <Select
        onClick={(e) => e.stopPropagation()}
        defaultValue={!!reference && `${reference.prefix}${reference.day}`}
        onChange={(reference) => onSelectReference({ session, reference })}
        style={{ width: "80%" }}
      >
        <Select.Option value={false} key="select-ref">
          {""}
        </Select.Option>
        {availableReferences
          .map((r) => ({
            value: formatRef(r),
            label: `${r.prefix}${r.day}`,
          }))
          .filter(
            ({ value }) => Object.values(takenReferences).indexOf(value) === -1
          )
          .map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
      </Select>
    ),
    align: "center",
    width: "12%",
  },
  {
    title: "Session Start",
    dataIndex: "sessionStart",
    key: "sessionStart",
    render: (_, { sessionStart }) => format(sessionStart, DATE_DISPLAY_FORMAT),
    align: "center",
  },
  {
    title: "Session Stop",
    dataIndex: "sessionStop",
    key: "sessionStop",
    render: (_, { sessionStop }) => format(sessionStop, DATE_DISPLAY_FORMAT),
    align: "center",
  },
  {
    title: "Report Issue",
    render: (_, { id, issues }) => {
      const hasIssues = issues.length > 0;
      const style: CSSProperties = {
        ...(hasIssues
          ? { borderRadius: "50%", height: "30px", width: "30px" }
          : {}),
      };
      return (
        <PrimaryButton
          onClick={(e) => {
            e.stopPropagation();
            onReportClick(id);
          }}
          style={style}
          danger
        >
          {hasIssues ? (
            <Tooltip title="Report" trigger={"hover"}>
              {issues.length}
            </Tooltip>
          ) : (
            "Report"
          )}
        </PrimaryButton>
      );
    },
    align: "center",
  },
  {
    title: () => (
      <DownloadOutlined
        onClick={(e) => {
          e.stopPropagation();
          onDownloadSessions();
        }}
      />
    ),
    render: (_, { id }) => (
      <DownloadOutlined
        onClick={(e) => {
          e.stopPropagation();
          onDownloadSession(id);
        }}
      />
    ),
    align: "center",
  },
];

export const TABLE_DATA = [
  {
    key: "1",
    session: "61234123",
    status: DeviceAnnotationStatusEnum.Pending,
    device: "CLW39868752",
    reviewed: false,
    group: "1",
    sessionStart: new Date(),
    sessionStop: new Date(),
  },
  {
    key: "2",
    session: "61234124",
    status: DeviceAnnotationStatusEnum.Initiated,
    device: "CLW39868752",
    reviewed: false,
    group: "1",
    sessionStart: new Date(),
    sessionStop: new Date(),
  },
  {
    key: "3",
    session: "61234125",
    status: DeviceAnnotationStatusEnum.UnderReview,
    device: "CLW39868752",
    reviewed: false,
    group: "1",
    sessionStart: new Date(),
    sessionStop: new Date(),
  },
  {
    key: "4",
    session: "61234126",
    status: DeviceAnnotationStatusEnum.Reviewed,
    device: "CLW39868752",
    reviewed: true,
    group: "1",
    sessionStart: new Date(),
    sessionStop: new Date(),
  },
];

export const transformSessionToModel = (
  session: Session,
  study: Study
): AnalysisSessionModel => {
  const rawIndex = session.index?.toString() as string;
  let sessionIndex = "";

  if (rawIndex?.length > 4) {
    sessionIndex = rawIndex;
  } else if (rawIndex?.length !== 0) {
    sessionIndex = rawIndex.padStart(4, "0");
  }

  let totalTasks = session.totalTasks;
  if (totalTasks === undefined || totalTasks === 0 || totalTasks === null) {
    totalTasks = 1; // To prevent dispalying NaN%
  }

  return {
    id: session.id,
    session: sessionIndex,
    status: (session.statusKey ??
      SubjectTotalStatusEnum.AnalysisPending) as SubjectTotalStatusEnum,
    device: session.deviceId as string,
    reviewed: session.statusKey === SessionStatusEnum.Reviewed,
    reference: session.reference as SessionReference,
    sessionStart: parseISO(session.startDate as string),
    sessionStop: parseISO(session.endDate as string),
    issues: (session.issues as DeviceSessionIssue[]) ?? [],
    availableReferences: study.sessionReferences ?? [],
    progress: ((session.completedTasks ?? 0) / totalTasks) * 100,
    completedTasks: session.completedTasks ?? 0,
    totalTasks: session.totalTasks ?? 0,
    storage: (
      (session.currentMemory ?? NaN) / (session.totalMemory ?? NaN)
    ).toLocaleString(undefined, { style: "percent" }),
    firmware: session.firmwareVersion ?? "N/A",
    hardware: session.hardwareVersion ?? "N/A",
    upload: session.uploadId ?? "N/A",
    selected: session.selected ?? false,
    deviceStartTime: parseISO(session.deviceStartDate),
    deviceStopTime: parseISO(session.deviceEndDate),
  };
};

export const transformSessionsToModels = (
  sessions: Session[],
  study: Study
): AnalysisSessionModel[] =>
  sessions.map((session) => ({
    ...transformSessionToModel(session, study),
    key: session.id,
  }));
