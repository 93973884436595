import styled from "styled-components";
// COMPONENTS

import { useGetManySitesQuery } from "generated/graphql";
import Caption from "components/text/Caption";
import Text from "components/text/Text";

export default function SitesNumberRenderer({
  trialsId,
  organizationId,
}: {
  trialsId: string;
  organizationId?: string;
}) {
  const { data } = useGetManySitesQuery({
    variables: {
      organizationIds: organizationId ? organizationId : "",
      trialIds: trialsId,
    },
  });
  return (
    <div>
      <Text style={{ color: "#515151" }}>{data?.getManySites?.length}</Text>
    </div>
  );
}
