import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { OtherDataUnitEnum, useOtherDataOptionsQuery } from "generated/graphql";
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const OtherDataSelectInput = (props: {
  onChange: (value: OtherDataUnitEnum) => void;
  value?: OtherDataUnitEnum | string | any;
  style?: React.CSSProperties;
  showIcon?: boolean;
  selectedOption?: string;
  pushedToClient?: string;
  options?: any[];
}) => {
  const { onChange, value = "", style, selectedOption, pushedToClient } = props;
  return (
    <div style={{ position: "relative" }}>
      <Select
        // {...props}
        size="large"
        onChange={onChange}
        value={value}
        optionFilterProp="children"
        placeholder="See more data"
        style={{
          width: "100%",
          margin: "auto",
          ...style,
        }}
      >
        {props?.options?.map((option) => {
          if (!option) return null;
          return (
            <Option
              style={
                value === option?.label
                  ? {
                      backgroundColor: "#e6f7ff",
                    }
                  : {}
              }
              key={option.key}
              value={option.key}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 12,
                }}
              >
                {option.label}
                {pushedToClient === option.key && (
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5.41 9.59002L4 11L8 15L16 7.00002L14.59 5.58002L8 12.17L5.41 9.59002Z"
                      fill="#50ACDE"
                    />
                  </svg>
                )}
              </div>
            </Option>
          );
        })}
      </Select>
      {pushedToClient === selectedOption &&
        pushedToClient &&
        selectedOption && (
          <div style={{ position: "absolute", right: 72, top: 12 }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM5.41 9.59002L4 11L8 15L16 7.00002L14.59 5.58002L8 12.17L5.41 9.59002Z"
                fill="#50ACDE"
              />
            </svg>
          </div>
        )}
    </div>
  );
};

// EXPORT
// ================================================================
export default OtherDataSelectInput;
