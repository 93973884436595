import { format } from "date-fns";
import styled from "styled-components";
import { Checkbox, Divider } from "antd";
import { useCallback, useMemo, useState } from "react";
import { StyledContainer } from "components/tables/SessionLogTable";
import {
  DeviceSessionIssue,
  DeviceSessionIssueTypeEnum,
  useResolveSessionIssueMutation,
} from "generated/graphql";
import {
  AbstractCheckboxProps,
  CheckboxChangeEvent,
} from "antd/lib/checkbox/Checkbox";
import {
  BaseCard,
  CardBoldText,
  CardRegularText,
} from "components/tables/AnalysisSessionTable/card-elements";

const DATE_DISPLAY_FORMAT = "MM/dd/yy, h:mm a";

const IssuesTypes = {
  [DeviceSessionIssueTypeEnum.ClientReported]: "Client Reported",
  [DeviceSessionIssueTypeEnum.DeviceIssue]: "Device Issue",
  [DeviceSessionIssueTypeEnum.OtherIssue]: "Other",
  [DeviceSessionIssueTypeEnum.ParticipantReported]: "Participant Reported",
  [DeviceSessionIssueTypeEnum.UploadIssue]: "Upload Issue",
};

interface IssueModel {
  id: string;
  type: string;
  created: Date;
  description: string;
}

const StyledIssueContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const transformDeviceIssueToIssueModel = (
  issue: DeviceSessionIssue
): IssueModel => ({
  id: issue.id as string,
  type: IssuesTypes[issue.issueType as DeviceSessionIssueTypeEnum],
  created: new Date(parseInt(issue.createdAt as string)),
  description: issue.description as string,
});

export const transformDeiveIssuesToIssueModels = (
  issues: DeviceSessionIssue[]
): IssueModel[] =>
  issues.map((issue) => ({
    ...transformDeviceIssueToIssueModel(issue),
    key: issue.id as string,
  }));

interface IssueProps {
  issue: IssueModel;
  reloadQuery: any;
  sessionId: String;
}

const Issue = ({ issue, reloadQuery, sessionId }: IssueProps) => {
  const { type, created, description } = issue;
  const [resolved, setResolved] = useState(false);
  const [mutate] = useResolveSessionIssueMutation();
  const handleResolved: AbstractCheckboxProps<CheckboxChangeEvent>["onChange"] =
    useCallback(
      (e: CheckboxChangeEvent) => {
        const { checked } = e.target;
        if (typeof issue.id === "string" && typeof sessionId === "string") {
          mutate({
            variables: {
              input: {
                sessionIssueId: issue.id,
                sessionId: sessionId,
                resolved: checked,
              },
            },
          }).then(() => {
            setResolved(checked);
            reloadQuery && reloadQuery();
          });
        }
      },
      [mutate, issue.id]
    );

  return (
    <StyledIssueContainer
      style={
        resolved
          ? {
              backgroundColor: "rgba(209, 209, 209, 0.5)",
            }
          : {}
      }
    >
      <p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <CardBoldText>Type: </CardBoldText>
            <CardRegularText>{type}</CardRegularText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 6,
            }}
          >
            <Checkbox checked={resolved} onChange={handleResolved} />
            <CardRegularText>Resolve{resolved ? "d" : ""}</CardRegularText>
          </div>
        </div>
        <CardBoldText>Created: </CardBoldText>
        <CardRegularText>
          {format(created, DATE_DISPLAY_FORMAT)}
        </CardRegularText>
        <br />
        <CardBoldText>Description:</CardBoldText>
        <br />
        <CardRegularText>{description}</CardRegularText>
      </p>
    </StyledIssueContainer>
  );
};

interface Props {
  issues: DeviceSessionIssue[];
  reloadQuery: any;
  deviceSessionId: any;
}

export const IssuesCard = ({
  issues = [],
  deviceSessionId,
  reloadQuery,
}: Props) => {
  const title = useMemo(() => `Issues (${issues.length ?? 0})`, [issues]);
  const data = useMemo(
    () => transformDeiveIssuesToIssueModels(issues),
    [issues]
  );

  return (
    <BaseCard title={title} titleColor="#AC4447">
      <StyledContainer>
        {data.map((issue) => (
          <Issue
            key={issue.id}
            sessionId={deviceSessionId}
            reloadQuery={reloadQuery}
            issue={issue}
          />
        ))}
      </StyledContainer>
      <Divider style={{ height: 3, marginTop: 10, marginBottom: 5 }} />
    </BaseCard>
  );
};
