import { message, Table } from "antd";
import PageTitle from "components/text/PageTitle";
import theme from "lib/theme";
import {
  ARCHIVED_TABLE_COLUMNS,
  TABLE_COLUMNS,
  transformSessionsToAvailableSessionModels,
} from "components/tables/AvailableSessionTable/data";
import {
  Session,
  SessionStatusEnum,
  useCreateSessionProjectMutation,
  useUpdateSessionReviewedMutation,
} from "generated/graphql";
import { useCallback, useMemo } from "react";
import { gql, useApolloClient } from "@apollo/client";

interface Props {
  sessions: Session[];
}

export const AvailableSessionTable = ({ sessions = [] }: Props) => {
  const data = useMemo(
    () => transformSessionsToAvailableSessionModels(sessions),
    [sessions]
  );

  const [createSessionProject] = useCreateSessionProjectMutation();
  const [updateSession] = useUpdateSessionReviewedMutation();
  const apolloClient = useApolloClient();

  const onAnalyseClick = useCallback(
    (uploadId: string, sessionId: string) => {
      (async () => {
        const messageKey = `analyse-${uploadId}`;
        message.loading({
          content: "Moving session to analysis...",
          key: messageKey,
        });

        try {
          const result = await createSessionProject({
            variables: {
              input: {
                uploadId,
              },
            },
          });

          const errors = result.data?.createSessionProject?.errors ?? [];
          const success = result.data?.createSessionProject?.success ?? false;

          if (errors.length > 0 || !success) {
            throw errors;
          }

          await updateSession({
            variables: {
              input: {
                id: sessionId,
                statusKey: SessionStatusEnum.AnalysisPending,
              },
            },
          });

          apolloClient.writeFragment({
            id: `Session:${sessionId}`,
            fragment: gql`
              fragment SessionStatus on Session {
                status
              }
            `,
            data: {
              status: {
                key: SessionStatusEnum.AnalysisPending,
              },
            },
          });

          message.success({
            content: "Moved to analysis.",
            key: messageKey,
            duration: 2,
          });
        } catch (error) {
          message.error({
            content: "Error sending to analysis",
            key: messageKey,
          });
          console.error(error);
        }
      })();
    },
    [apolloClient, createSessionProject, updateSession]
  );

  const onArchiveClick = useCallback(
    (sessionId: string) => {
      (async () => {
        const messageKey = `archive-session-${sessionId}`;
        message.loading({ content: "Archiving session...", key: messageKey });

        try {
          await updateSession({
            variables: {
              input: {
                id: sessionId,
                statusKey: SessionStatusEnum.Archived,
              },
            },
          });

          apolloClient.writeFragment({
            id: `Session:${sessionId}`,
            fragment: gql`
              fragment SessionStatus on Session {
                status
              }
            `,
            data: {
              status: { key: SessionStatusEnum.Archived },
            },
          });

          message.success({
            content: "Archived session.",
            key: messageKey,
            duration: 1,
          });
        } catch (error) {
          message.error({
            content: "Error archiving session.",
            key: messageKey,
            duration: 2,
          });
          console.error(error);
        }
      })();
    },
    [apolloClient, updateSession]
  );

  const columns = useMemo(
    () => TABLE_COLUMNS({ onAnalyseClick, onArchiveClick }),
    [onAnalyseClick, onArchiveClick]
  );

  return (
    <div>
      <PageTitle color={theme.colors.primary4}>Available Sessions</PageTitle>
      <Table size="small" columns={columns} dataSource={data} />
    </div>
  );
};

export const ArchivedSessionTable = ({ sessions = [] }: Props) => {
  const data = useMemo(
    () => transformSessionsToAvailableSessionModels(sessions),
    [sessions]
  );

  const [updateSession] = useUpdateSessionReviewedMutation();
  const apolloClient = useApolloClient();

  const onUnarchiveClick = useCallback(
    (sessionId: string) => {
      (async () => {
        const messageKey = `unarchive-session-${sessionId}`;
        message.loading({ content: "Unarchiving session...", key: messageKey });

        try {
          await updateSession({
            variables: {
              input: {
                id: sessionId,
                statusKey: SessionStatusEnum.DataAvailable,
              },
            },
          });

          apolloClient.writeFragment({
            id: `Session:${sessionId}`,
            fragment: gql`
              fragment SessionStatus on Session {
                status {
                  key
                }
              }
            `,
            data: {
              status: { key: SessionStatusEnum.DataAvailable },
            },
          });

          message.success({
            content: "Unarchived session.",
            key: messageKey,
            duration: 1,
          });
        } catch (error) {
          message.error({
            content: "Error unarchiving session.",
            key: messageKey,
            duration: 2,
          });
          console.error(error);
        }
      })();
    },
    [apolloClient, updateSession]
  );

  const columns = useMemo(
    () =>
      ARCHIVED_TABLE_COLUMNS({
        onUnarchiveClick,
      }),
    [onUnarchiveClick]
  );

  return (
    <div>
      <PageTitle color={theme.colors.primary4}>Archived Sessions</PageTitle>
      <Table size="small" columns={columns} dataSource={data} />
    </div>
  );
};
