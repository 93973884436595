import { Table } from "antd";
import { SubjectSummary } from "generated/graphql";
import moment from "moment";
import { ReactElement } from "react";

enum Status {
  OnHold = "On Hold",
  Reviewed = "Reviewed",
  Terminated = "Terminated",
  Completed = "Completed",
}

interface Props {
  clientView?: boolean;
  siteName?: string;
  openByDefault?: string | null;
  record: SubjectSummary;
  reloadQuery: () => void;
  createIssueButton: ReactElement<any, any>;
}

export default function ExpandedRow({ record, siteName }: Props) {
  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Session</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{record?.index}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Device</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{record?.deviceId}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Status</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          {Status[record?.statusKey as keyof typeof Status]}
        </div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>HW</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{record?.hardwareVersion}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>FW</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{record?.firmwareVersion}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Site</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{siteName}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Stage</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>{record?.studyStage}</div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Assigned</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          {record.assignedDate
            ? moment(record.assignedDate).format("MM/DD/YY")
            : ""}
        </div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Uploaded</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          {record.uploadDate
            ? moment(record.uploadDate).format("MM/DD/YY")
            : ""}
        </div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Days</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          {moment(record.endDate).diff(moment(record.startDate), "days") + 1}
        </div>
      ),
      width: 60,
    },
    {
      title: <div style={{ textAlign: "center" }}>Issues</div>,
      sorter: true,
      render: (record: any) => (
        <div style={{ textAlign: "center" }}>
          {record?.issues?.length ? record?.issues?.length : 0}
        </div>
      ),
      width: 60,
    },
  ];

  return (
    <div
      style={{
        paddingBottom: 0,
        paddingTop: 10,
        border: 0,
        background: "transparent",
      }}
    >
      <Table
        style={{ maxWidth: 1400 }}
        columns={columns as any}
        className="tableClass"
        rowKey="id"
        dataSource={record?.sessions ? record?.sessions : ([] as any)}
      />
    </div>
  );
}
