import Table from "components/common/Table";
import { CSSProperties, Key, useState } from "react";
import {
  SubjectSummary,
  DeviceSession,
  SortOrderEnum,
  useDownloadDeviceEventsXptMutation,
  useGetManyDeviceEventsLazyQuery,
  UserProfile,
  UserTypeEnum,
  SessionStatusEnum,
} from "generated/graphql";

import {
  DownloadOutlined,
  MinusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import IssueModal from "./IssueModal";
import Tooltip from "components/common/Tooltip";
import Button from "components/common/Button";
import TruncateText from "components/common/TruncateText";
import ExpandedRow from "./ExpandedRow";
import styled from "styled-components";
import theme from "lib/theme";
import moment from "moment";

import useDownloadCSV from "lib/hooks/useDownloadCSV";
import useOnDownloadSessionsSummary from "./useOnDownloadSessionsSummary";

// HOOKS

import PageCount from "./PageCount";
import DownloadOptionsModal, {
  DownloadTypeEnum,
  EventDownloadFileType,
} from "./DownloadOptionsModal";
import forceDownloadFile from "lib/helpers/forceDownloadFile";
import IconChartCard from "lib/media/iconChartCard";
import FilterColumns from "components/common/FilterColumn";
import { Popover } from "antd";

const statusesEnums: any = {
  Available: "Available",
  AnalysisPending: "Analysis Pending",
  AnalysisInitiated: "Analysis Initiated",
  UnderReview: "Under Review",
  Reviewed: "Reviewed",
  Completed: "Completed",
};

const statusOptions = [
  "Available",
  "AnalysisPending",
  "AnalysisInitiated",
  "UnderReview",
  "Reviewed",
  "Completed",
];

const ChangeText = styled.div<{ mathSign: -1 | 1 | 0 }>`
  font-weight: 600;
  color: ${({ mathSign, theme }) => {
    if (mathSign === -1) {
      return theme.colors.success4;
    }
    if (mathSign === 1) {
      return theme.colors.red4;
    }
    return theme.colors.neutral2;
  }};
`;

const DataText = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary3};
`;

const IssueContainer = styled.div`
  height: 28px;
  cursor: pointer;
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red9};
  color: ${({ theme }) => theme.colors.red2};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
`;

const iconStyles: any = {
  marginRight: 8,
  fontSize: 17,
  position: "relative",
  top: 2,
};

export enum SessiontTableColEnum {
  /**  */
  startDate = "startDate",
  /**  */
  subjectName = "subjectName",
  /** This column will show the session ID and let the user filter by searching session IDs. Okay for clients and admin */
  sessionId = "sessionId",
  /** This column will show the device ID and let the user filter by searching device IDs. Okay for clients and admin */
  deviceId = "deviceId",
  /** This column will show the client name. Generally only for the admins, a client user doesnt need to see a column with their name   */
  client = "client",
  /** This column will show the first day of the session. Okay for clients and admin */
  firstDay = "firstDay",
  /** This column will show the last day of the session. Okay for clients and admin */
  lastDay = "lastDay",
  /** This column will show the change between first day of data and las day of data in the session. Okay for clients and admin */
  change = "change",
  /** This column will show the number of days in the session. Used for admin only. Clients shouldn't see annotation/analysis status (clients only see completed analysis) */
  annotationStatus = "annotationStatus",
  /** column will let admin toggle if a session is ready for release */
  released = "released",
  /** will show how many issues a session has. admins only */
  issues = "issues",
  /** will show how many issues a session has. admins only */
  download = "download",
  /** download column for clients only */
  clientDownload = "clientDownload",
  /** column that will let users report an issue. for clients only */
  reportIssue = "reportIssue",
  /** column showing the entire spectrum of statuses. admins only... clients should only see status=released.   */
  sessionStatus = "sessionStatus",
  /** column showing limited status (No issue, has issue). Specifically for clients. */
  clientSessionStatus = "clientSessionStatus",
  sessions = "sessions",
}
export enum subjectTableColEnum {
  SubjectId = "subjectId",
  Status = "status",
  Client = "client",
  FirstDayCoughs = "firstDayCoughs",
  LastDayCoughs = "lastDayCoughs",
  Change = "change",
  Issues = "issues",
  clientDownload = "clientDownload",
}

const saveTemplateAsFile = (filename: string, dataObjToWrite: object) => {
  const blob = new Blob([JSON.stringify(dataObjToWrite)], {
    type: "text/json",
  });
  const link = document.createElement("a");

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};

const ColTitleCenter = styled.span`
  display: block;
  width: 100%auto;
  text-align: center;
`;

interface Props {
  cols: subjectTableColEnum[];
  dataSource: any[];
  loading?: boolean;
  pagination?: any;
  onChange?: any;
  variables?: any;
  currentUser: UserProfile;
  scroll?: undefined | { x: number };
  sort?: { field: subjectTableColEnum; order: SortOrderEnum }[];
  clientView?: boolean;
  downloadSubjectReportFiles?: (subjectId: string) => void;
  downloadSubjectsSummaries?: () => void;
  reloadQuery?: () => void;
}

export default function SessionsTable({
  dataSource,
  loading,
  pagination,
  variables,
  cols,
  currentUser,
  scroll,
  clientView,
  onChange,
  downloadSubjectReportFiles = (_) => void _,
  downloadSubjectsSummaries = () => void 33,
  reloadQuery = () => void 36,
}: Props) {
  const searchParams = new URLSearchParams(window.location.search);

  const [loadEvents] = useGetManyDeviceEventsLazyQuery();
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: {},
    order: SortOrderEnum,
  });
  const [downloadDeviceEventsXptMutation] =
    useDownloadDeviceEventsXptMutation();
  const [downloadVisible, setDownloadVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<DownloadTypeEnum | false>(
    false
  );

  const [onDownloadSessions, downloadingSessionSummary] =
    useOnDownloadSessionsSummary();

  const [downloadFile] = useDownloadCSV();

  const [showIssueModal, setShowIssueModal] = useState<string | false>(false);
  const [issueSelectedSubject, setIssueSelectedSubject] = useState<string>("");
  const [issueSelectedSubjectID, setIssueSelectedSubjectID] =
    useState<string>("");

  const handleChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortedInfo(sorter);
    onChange(pagination, _filters, sorter);
  };

  const columns = [
    {
      title: "Subject ID",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => {
        return (
          <FilterColumns
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            dataSource={dataSource}
            placeHolder="Search By Subject ID"
          />
        );
      },
      onFilter: (value: string, record: SubjectSummary) => {
        return (
          record.subjectId
            ?.toLowerCase()
            .indexOf(value.toString().toLowerCase()) === 0
        );
      },
      filterIcon: () => (
        <SearchOutlined
          style={{
            color: theme.colors.gray2,
            fontSize: 18,
          }}
        />
      ),
      fixed: "left",
      width: 60,
      key: subjectTableColEnum.SubjectId,
      render: (record: SubjectSummary) => <strong>{record.subjectId}</strong>,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <Tooltip title="Is this session ready to be released?">
            Status
          </Tooltip>
        </div>
      ),
      key: subjectTableColEnum?.Status,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aNum = SubjectStatusOrder[a?.status?.key ?? ""] ?? 0;
      //   const bNum = SubjectStatusOrder[b?.status?.key ?? ""] ?? 0;

      //   return aNum - bNum;
      // },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "status" ? sortedInfo.order : null,
      width: 60,
      // key: SessiontTableColEnum.released,

      render: (record: SubjectSummary) => {
        const renderLabel = (value: any) => {
          switch (value?.key) {
            case "Available":
              return (
                <MinusCircleOutlined
                  style={{
                    color: theme.colors?.gray2,
                    fontSize: 20,
                  }}
                />
              );
            case "AnalysisPending":
              return <IconChartCard pieChartQtr />;
            case "AnalysisInitiated":
              return <IconChartCard pieChartHalf />;
            case "UnderReview":
              return <IconChartCard underReview />;
            case "Reviewed":
              return <IconChartCard reviewed />;
            case "Completed":
              return <IconChartCard completed />;

            default:
              return <div />;
          }
        };
        const mapRecordStatus =
          record?.sessions?.reduce?.((acc, key) => {
            if (!key?.selected) {
              return [...acc];
            }
            const status = key?.statusKey;
            return [...acc, status];
          }, [] as (SessionStatusEnum | null | undefined)[]) ?? [];

        let count = [0, 0, 0, 0, 0, 0];
        mapRecordStatus.forEach((record) => {
          switch (record) {
            case statusOptions[0]:
              count[0]++;
              break;
            case statusOptions[1]:
              count[1]++;
              break;
            case statusOptions[2]:
              count[2]++;
              break;
            case statusOptions[3]:
              count[3]++;
              break;
            case statusOptions[4]:
              count[4]++;
              break;
            case statusOptions[5]:
              count[5]++;
              break;
            default:
              break;
          }
        });
        interface RecordCount {
          key: string;
          count: number;
        }

        const recordCount: RecordCount[] = count
          .map((num, index) => ({
            key: statusOptions[index],
            count: num,
          }))
          .filter(({ count }) => count > 0);

        const content = () => (
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {recordCount?.map((value, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {renderLabel(value)}
                {statusesEnums[value?.key ? value?.key : "Available"]} (
                {value?.count}){" "}
              </div>
            ))}
          </div>
        );
        if (clientView) {
          const hasSubjectIssues = (record?.issues?.length ?? 0) !== 0;
          const hasSessionIssues = !!record?.sessions?.find(
            (s) => s?.issues?.length !== 0
          );

          if (hasSubjectIssues || hasSessionIssues) {
            return (
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  paddingRight: "10px",
                }}
              >
                <IconChartCard completed />
              </div>
            );
          }
          return (
            <MinusCircleOutlined
              style={{
                color: theme.colors?.gray2,
                fontSize: 17,
                position: "relative",
                display: "block",
              }}
            />
          );
        } else {
          switch (record?.status?.key) {
            case "Available":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div style={{ textAlign: "center" }}>
                    <MinusCircleOutlined
                      style={{
                        color: theme.colors?.gray2,
                        fontSize: 20,
                        cursor: "pointer",
                        paddingRight: "10px",
                      }}
                    />
                  </div>
                </Popover>
              );
            case "AnalysisPending":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  >
                    <IconChartCard pieChartQtr />
                  </div>
                </Popover>
              );
            case "AnalysisInitiated":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  >
                    <IconChartCard pieChartHalf />
                  </div>
                </Popover>
              );
            case "UnderReview":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  >
                    <IconChartCard underReview />
                  </div>
                </Popover>
              );
            case "Reviewed":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  >
                    <IconChartCard reviewed />
                  </div>
                </Popover>
              );
            case "Completed":
              return (
                <Popover
                  mouseEnterDelay={0.5}
                  content={content}
                  trigger="hover"
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      paddingRight: "10px",
                    }}
                  >
                    <IconChartCard completed />
                  </div>
                </Popover>
              );

            default:
              return null;
          }
        }
      },
    },
    {
      title: <>Start Date</>,
      width: "10%",
      key: SessiontTableColEnum.startDate,
      render: (record: DeviceSession) =>
        record.startDate &&
        moment(parseInt(record.startDate)).format("MM/DD/YY"),
    },
    {
      title: <ColTitleCenter>Status</ColTitleCenter>,
      width: 45,
      key: SessiontTableColEnum.clientSessionStatus,
      render: (record: DeviceSession) => (
        <div style={{ textAlign: "center" }}>
          {record?.issues?.[0] ? (
            <MinusCircleOutlined
              style={{
                color: theme.colors?.gray2,
                marginRight: 8,
                fontSize: 17,
                position: "relative",
                // top: 2,
              }}
            />
          ) : (
            <div
              style={{
                marginRight: 8,
                fontSize: 17,
                position: "relative",
              }}
            >
              <IconChartCard completed />
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Client",
      key: subjectTableColEnum?.Client,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aString = a?.client?.name ?? "";
      //   const bString = b?.client?.name ?? "";

      //   return aString.localeCompare(bString);
      // },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "client" ? sortedInfo.order : null,

      render: (record: SubjectSummary) => (
        <TruncateText text={record?.client?.name}>
          <span> {record?.client?.name}</span>
        </TruncateText>
      ),
      width: 80,
    },
    {
      title: <ColTitleCenter>First Day Coughs</ColTitleCenter>,
      key: subjectTableColEnum?.FirstDayCoughs,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aString = a?.dailyReport?.firstDayCoughs ?? 0;
      //   const bString = b?.dailyReport?.firstDayCoughs ?? 0;

      //   return aString - bString;
      // },
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "firstDayCoughs" ? sortedInfo.order : null,
      width: 110,

      render: (record: SubjectSummary) => (
        <DataText style={{ textAlign: "center" }}>
          {record?.dailyReport?.firstDayCoughs?.toLocaleString()}
        </DataText>
      ),
    },
    {
      title: <ColTitleCenter>Last Day Coughs</ColTitleCenter>,
      key: subjectTableColEnum?.LastDayCoughs,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aString = a?.dailyReport?.lastDayCoughs ?? 0;
      //   const bString = b?.dailyReport?.lastDayCoughs ?? 0;

      //   return aString - bString;
      // },
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === "lastDayCoughs" ? sortedInfo.order : null,
      width: 110,
      render: (record: SubjectSummary) => (
        <DataText style={{ textAlign: "center" }}>
          {record?.dailyReport?.lastDayCoughs?.toLocaleString()}
        </DataText>
      ),
    },
    {
      title: <ColTitleCenter>Change</ColTitleCenter>,
      width: 70,
      key: subjectTableColEnum?.Change,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aString = a?.dailyReport?.coughsChange ?? 0;
      //   const bString = b?.dailyReport?.coughsChange ?? 0;

      //   return aString - bString;
      // },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "change" ? sortedInfo.order : null,

      render: (record: SubjectSummary) => {
        if (
          !record?.dailyReport?.coughsChange &&
          record?.dailyReport?.coughsChange !== 0
        )
          return null;
        const mathSign = Math.sign(record?.dailyReport?.coughsChange);
        return (
          <ChangeText
            mathSign={mathSign as -1 | 1 | 0}
            style={{ textAlign: "center" }}
          >
            {mathSign === 1 ? "+" : null}
            {record?.dailyReport?.coughsChange.toLocaleString()}
          </ChangeText>
        );
      },
    },

    {
      title: <ColTitleCenter>Issues</ColTitleCenter>,
      width: 100,
      render: (record: DeviceSession) => (
        <Button
          danger
          onClick={(e) => {
            e.stopPropagation();
            setShowIssueModal(record.id);
          }}
          style={{ margin: "auto", display: "block", height: 30 }}
        >
          Create Issue
        </Button>
      ),
      key: SessiontTableColEnum?.reportIssue,
    },
    {
      title: <ColTitleCenter style={{ marginRight: 2 }}>Issues</ColTitleCenter>,
      // sorter: (a: SubjectSummary, b: SubjectSummary) => {
      //   const aString =
      //     a?.sessions?.map((key) => {
      //       return a?.issues?.concat(key?.issues?.length as DeviceSessionIssue);
      //     }) ?? [];
      //   const bString =
      //     b?.sessions?.map((key) => {
      //       return b?.issues?.concat(key?.issues?.length as DeviceSessionIssue);
      //     }) ?? [];

      //   return aString.length - bString.length;
      // },
      sorter: true,
      sortOrder: sortedInfo?.columnKey === "issues" ? sortedInfo.order : null,
      width: 100,
      key: subjectTableColEnum?.Issues,
      render: (record: SubjectSummary) => {
        const subjectIssues =
          record?.issues?.filter((issue) => !issue?.resolved)?.length ?? 0;
        const sessionIssues =
          record?.sessions?.reduce(
            (acc, session) =>
              acc +
              (session?.issues?.filter((issue) => !issue?.resolved)?.length ??
                0),
            0
          ) ?? 0;
        const issuesCount = subjectIssues + (sessionIssues ? 1 : 0);
        const hasIssues = issuesCount > 0;
        const style: CSSProperties = {
          ...(hasIssues
            ? { borderRadius: "50%", height: "30px", width: "30px" }
            : {}),
        };

        return (
          <Button
            danger
            onClick={(e) => {
              e.stopPropagation();
              setModalOpen(true);
              setIssueSelectedSubject(record?.id ?? "");
              setIssueSelectedSubjectID(record?.subjectId ?? "");
            }}
            style={{
              margin: "auto",
              display: "block",
              height: 30,
              ...style,
            }}
          >
            {hasIssues ? (
              <Tooltip title="Create Issue" trigger={"hover"}>
                {issuesCount.toLocaleString()}
              </Tooltip>
            ) : (
              "Report"
            )}
          </Button>
        );
      },
    },
    {
      title: (
        <div>
          <Tooltip title="View download options">
            <DownloadOutlined
              onClick={(e) => {
                e.stopPropagation();
                setDownloadVisible(true);
              }}
            />
          </Tooltip>
        </div>
      ),
      width: 30,
      key: SessiontTableColEnum.download,
      render: (record: DeviceSession) => (
        <Tooltip title="Download session">
          <DownloadOutlined
            onClick={async (e) => {
              e.stopPropagation();
              saveTemplateAsFile(
                `${record.sessionId} ${record.deviceId} ${moment().format(
                  "MMM DD, YYYY"
                )}`,
                record
              );
            }}
          />
        </Tooltip>
      ),
    },
    {
      title: (
        <div>
          <Tooltip title="Download Subjects CSV">
            <DownloadOutlined
              onClick={(e) => {
                e.stopPropagation();
                downloadSubjectsSummaries();
              }}
            />
          </Tooltip>
        </div>
      ),
      width: 30,
      key: SessiontTableColEnum.clientDownload,
      render: ({ id }: SubjectSummary) => {
        return (
          <Tooltip title="Download report">
            <DownloadOutlined
              onClick={(e) => {
                e.stopPropagation();
                downloadSubjectReportFiles(id as string);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const onDownloadEventsCSV = async () => {
    try {
      const result = await loadEvents({
        variables: {
          organizationIds: variables?.organizationIds,
          active: variables?.active === "true" ? true : undefined,
          siteIds: variables?.siteIds,
          trialIds: variables?.trialIds,
          sessionStatuses: variables?.sessionStatuses
            ? (variables?.sessionStatuses as number[])
            : [],
          // annotationStatuses: variables?.annotationStatuses
          //   ? (variables?.annotationStatuses as DeviceAnnotationStatusEnum[])
          //   : [],
          sessionId: (variables?.sessionId?.[3]
            ? variables?.sessionId
            : "") as string,
          deviceId: (variables?.deviceId?.[3]
            ? variables?.deviceId
            : "") as string,
        },
      });

      downloadFile(
        result?.data?.getManyDeviceEvents?.results?.map((item) => ({
          Session: item.sessionId?.trim()?.toString(),
          Time:
            item?.timestamp &&
            moment(parseInt(item?.timestamp))?.utc()?.toString(),
          Awake: Math.random() < 0.5,
        })) || [],
        `EventsDownload-${moment()?.format("MMM-DD-YYYY")}.csv` //`Download-${moment()?.format("MMM-DD-YYYY")}.csv`
      );
    } catch (err) {}
  };

  return (
    <div>
      <Table
        style={{ maxWidth: 1400 }}
        scroll={scroll}
        columns={
          columns?.filter((item) => cols.includes(item?.key as any)) as any
        }
        className="tableClass"
        rowClassName={
          (_, index) => (Boolean(index % 2) ? "table-row-bg" : "") // clickup issue = 1rp4pd3 | will add a special classname to every other row so we can color tham differently
        }
        rowKey="id"
        loading={loading}
        pagination={{ pageSizeOptions: [10, 20, 50, 100], ...pagination }}
        onChange={handleChange}
        expandRowByClick
        defaultExpandedRowKeys={[searchParams.get("sessionSubject") as Key]}
        expandable={{
          expandedRowRender: (record: SubjectSummary) => (
            <ExpandedRow
              createIssueButton={
                <Button
                  danger={!clientView}
                  lightblue={clientView}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalOpen(true);
                    setIssueSelectedSubject(record?.id ?? "");
                    setIssueSelectedSubjectID(record?.subjectId ?? "");
                  }}
                  style={{
                    display: "block",
                    height: 30,
                    position: "absolute",
                    right: 10,
                  }}
                >
                  Create Issue
                </Button>
              }
              openByDefault={
                searchParams.get("sessionSubject")
                  ? searchParams.get("sessionSubject")
                  : null
              }
              key={record.subjectId}
              record={record}
              clientView={clientView}
              reloadQuery={reloadQuery}
            />
          ),
          rowExpandable: () => true,
        }}
        dataSource={dataSource}
      />
      {/* The page count that shows at the bottom of the table */}
      {!loading && pagination?.total > 0 && (
        <PageCount
          pagination={pagination}
          lastResult={pagination?.current * pagination?.pageSize}
        />
      )}
      {/* Modal for submitting an issue about a device session*/}
      <IssueModal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        deviceSessionId={showIssueModal as string}
        clientView={clientView}
        subjectId={issueSelectedSubject}
        subjectIdToDisplay={issueSelectedSubjectID}
      />
      {/* Modal for downloading device sessions or device events */}
      <DownloadOptionsModal
        visible={downloadVisible}
        downloading={downloadingSessionSummary || downloading}
        onCancel={() => setDownloadVisible(false)}
        onDownloadEvents={async (fileType) => {
          try {
            if (fileType === EventDownloadFileType.CSV) {
              await onDownloadEventsCSV();
            }
            if (fileType === EventDownloadFileType.XPT) {
              setDownloading(DownloadTypeEnum.DeviceEventsXPT);
              const res = await downloadDeviceEventsXptMutation({
                variables: {
                  query: {
                    // batchIds: [ID!],
                    // issueStatuses: [DeviceSessionIssueTypeEnum!]
                    organizationIds: variables?.organizationIds,
                    active: variables?.active === "true" ? true : undefined,
                    siteIds: variables?.siteIds,
                    trialIds: variables?.trialIds,
                    sessionStatuses: variables?.sessionStatuses
                      ? (variables?.sessionStatuses as number[])
                      : [],
                    // annotationStatuses: variables?.annotationStatuses
                    //   ? (variables?.annotationStatuses as DeviceAnnotationStatusEnum[])
                    //   : [],
                    sessionId: (variables?.sessionId?.[3]
                      ? variables?.sessionId
                      : "") as string,
                    deviceId: (variables?.deviceId?.[3]
                      ? variables?.deviceId
                      : "") as string,
                  },
                },
              });
              if (
                res?.data?.downloadDeviceEventsXpt?.url &&
                res?.data?.downloadDeviceEventsXpt?.filename
              ) {
                forceDownloadFile(
                  res?.data?.downloadDeviceEventsXpt?.url,
                  res?.data?.downloadDeviceEventsXpt?.filename?.replace(
                    "output/",
                    ""
                  )
                );
              }
            }
            setDownloading(false);
            setDownloadVisible(false);
          } catch (err) {
            setDownloading(false);
            console.log(err);
          }
        }}
        onDownloadSessions={async () => {
          const fieldsToRemove =
            currentUser?.userType === UserTypeEnum.Client
              ? ["Device ID", "Status", "Client", "Annotation"]
              : undefined;
          await onDownloadSessions(variables, fieldsToRemove);
          setDownloadVisible(false);
        }}
      />
    </div>
  );
}
